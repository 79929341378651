import React from 'react'

import useImageUpload from '~/app/state/hooks/useImageUpload'
import { FieldSet, UploadMedia } from '~/shared/components'
import type { onSelectLayoutParams } from '../index'

import Giphy from '../Giphy'
import { StyledText, LighterStyledText, Row, Button, Image } from './styles'

const LAYOUT_THUMBNAILS: {
  [key: string]: {
    src: string
    alt: string
  }
} = {
  NoImageVertical: {
    src: 'https://buffer-start-page.s3.amazonaws.com/header-layout-no-image-vertical.png',
    alt: 'A header layout without background image, with vertical aligment for logo, title and description',
  },
  NoImageHorizontal: {
    src: 'https://buffer-start-page.s3.amazonaws.com/header-layout-no-image-horizontal.png',
    alt: 'A header layout without background image, with horizontal aligment for logo, title and description',
  },
  ImageFullVertical: {
    src: 'https://buffer-start-page.s3.amazonaws.com/header-layout-image-full-vertical.png',
    alt: 'A header layout with tall background image, with vertical aligment for logo, title and description',
  },
  ImageHalfVertical: {
    src: 'https://buffer-start-page.s3.amazonaws.com/header-layout-image-half-vertical.png',
    alt: 'A header layout with half background image, with vertical aligment for logo, title and description',
  },
}

const LAYOUTS_WITHOUT_IMAGE = ['NoImageVertical', 'NoImageHorizontal']

const LAYOUTS_WITH_IMAGE = ['ImageFullVertical', 'ImageHalfVertical']

/**
 *
 */
function Layouts({
  layout,
  backgroundImage,
  onSelect,
}: {
  layout: string
  backgroundImage?: string
  onSelect: (arg0: onSelectLayoutParams) => void
}) {
  const [selectedLayout, setSelectedLayout] = React.useState(layout)

  const {
    imageUrl: selectedBackgroundImageFromUpload,
    setSelectedFile: setUploadedBackgroundImage,
    imageUploading: backgroundImageUploading,
    errorUploading: backgroundImageError,
  } = useImageUpload(backgroundImage || '')

  const [selectedGifBackgroundImage, setSelectedGifBackgroundImage] =
    React.useState(backgroundImage || '')

  React.useEffect(() => {
    onSelect({
      selectedLayout,
      selectedBackgroundImage: selectedBackgroundImageFromUpload,
    })
  }, [selectedLayout, selectedBackgroundImageFromUpload])

  React.useEffect(() => {
    onSelect({
      selectedLayout,
      selectedBackgroundImage: selectedGifBackgroundImage,
    })
  }, [selectedLayout, selectedGifBackgroundImage])

  const renderLayouts = (layouts: string[]) =>
    layouts.map((name: string) => {
      const thumbnail = LAYOUT_THUMBNAILS[name]
      if (!thumbnail) return null

      const selected = name === selectedLayout

      return (
        <Button
          key={name}
          selected={selected}
          aria-pressed={selected}
          onClick={() => setSelectedLayout(name)}
        >
          <Image src={thumbnail.src} alt={thumbnail.alt} />
        </Button>
      )
    })

  const layoutWithImage = LAYOUTS_WITH_IMAGE.includes(selectedLayout)

  return (
    <FieldSet>
      <StyledText type="p">Without banner</StyledText>
      <Row data-testid="layouts-without-image">
        {renderLayouts(LAYOUTS_WITHOUT_IMAGE)}
      </Row>
      <StyledText type="p">With banner</StyledText>
      <Row data-testid="layouts-with-image">
        {renderLayouts(LAYOUTS_WITH_IMAGE)}
      </Row>
      {layoutWithImage && (
        <>
          <StyledText htmlFor="background" type="label">
            Background image
          </StyledText>
          <UploadMedia
            id="background"
            smallPreview={selectedBackgroundImageFromUpload}
            setSelectedFile={setUploadedBackgroundImage}
            loading={backgroundImageUploading}
            errorUploading={backgroundImageError}
          />
          <LighterStyledText type="p">
            Or try these GIFs from Giphy:
          </LighterStyledText>
          <Giphy setSelectedFile={setSelectedGifBackgroundImage} />
        </>
      )}
    </FieldSet>
  )
}

export default Layouts
