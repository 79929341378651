import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { Logo } from '@buffer-mono/sp-display'

export const Label = styled(Text)`
  margin: 0px 16px 8px;
  font-weight: 500;
  overflow: visible;
`

export const LogoStyled = styled(Logo)`
  border-radius: 8px;
`
