import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { MUTATION_ADD_BLOCK } from '~/app/state/mutations'
import useSaveTheme from '~/app/state/hooks/useSaveTheme'

import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import { useStartPageContext } from '~/modules/pages/state/context'

import type { NewBlock } from '~/shared/interfaces'
import type {
  StartPage,
  IStartPageTheme,
  BlockStyles,
} from '@buffer-mono/sp-display'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import { useThemes } from '~/modules/themes/hooks/useThemes'

export type ChangesState = {} | null

type UseAddBlockProps = {
  block: NewBlock
  changes: ChangesState
}

/*
 *   When a new block is added, we need to add the block styles for that block
 *   to the theme. If the theme doesn't have a block style for the new block, we
 *   add it to the theme before saving the block changes to the DB.
 */
const getBlockStylesForNewBlock = (
  theme: IStartPageTheme | undefined,
  block: NewBlock,
  themes: [IStartPageTheme],
): BlockStyles | undefined => {
  if (theme?.id && themes) {
    // we get the styles for the new block from the original theme template
    const originalTheme = themes?.find(
      (th: IStartPageTheme) => th.id === theme?.id,
    )
    if (originalTheme) {
      return originalTheme?.blockStyles.find((b) => b.blockType === block.type)
    }
  }
  return undefined
}

export default function ({ block, changes }: UseAddBlockProps) {
  const { startPageData, setStartPageData } = useStartPageContext()
  const { trackBlockAdded } = useTracking()
  const [saveChangesToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_ADD_BLOCK)
  const { queryThemes, themes } = useThemes()
  const { saveTheme } = useSaveTheme()

  useEffect(() => {
    queryThemes()
  }, [queryThemes])

  const addBlock = () => {
    if (!changes) return

    const { blocks = [], theme } = startPageData || {}

    const config = {
      type: block.type,
      data: changes,
    }

    /* If the StartPage template doesn't contain a blockStyle for the new added
    block, we need to update the theme before saving the block changes to the DB */
    if (!theme?.blockStyles.some((b) => b.blockType === block.type)) {
      const newStylesForNewBlock = getBlockStylesForNewBlock(
        theme,
        block,
        themes,
      )
      if (newStylesForNewBlock && theme) {
        theme.blockStyles.push(newStylesForNewBlock)
        saveTheme(theme)
      } else {
        console.error('Could not find block styles for the new block')
        return
      }
    }

    const newBlockArray = [
      ...blocks,
      { __typename: block.__typename, ...changes, order: blocks.length + 1 },
    ]

    /* update state - we need to do it manually instead of relying on apollo client
        because if a new block is added just after create a new page, the page is not
        in the cache yet */
    setStartPageData((prevData: any) => ({
      ...prevData,
      hasUnPublishedChanges: true,
      blocks: newBlockArray,
    }))
    const pageId = startPageData?.id

    saveChangesToDB({
      variables: {
        config,
        pageId,
        organizationId: startPageData?.organizationId,
      },
      update(cache, { data: { addBlock } }) {
        const allStartPageData = cache.readQuery<StartPage>({
          query: QUERY_START_PAGE,
          variables: {
            pageId,
            organizationId: startPageData?.organizationId,
          },
        })
        cache.writeQuery({
          query: QUERY_START_PAGE,
          data: {
            startPage: {
              ...allStartPageData?.startPage,
              hasUnPublishedChanges: true,
              blocks: [
                ...(allStartPageData?.startPage.blocks || []),
                addBlock.block,
              ],
            },
          },
        })
      },
    })
      .then(() => {
        trackBlockAdded(block)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return {
    addBlock,
    response: response || null,
    mutationError,
    loading,
  }
}
