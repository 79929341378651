import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import TextArea from '@bufferapp/ui/TextArea'
import { FieldWrapper } from '../styles'

interface StyledTextAreaProps {
  withCounter?: boolean
  withError?: boolean
}

export const StyledTextArea = styled.div<StyledTextAreaProps>`
  ${(props) => {
    const { withCounter, withError } = props

    return css`
      position: relative;
      margin-bottom: ${withCounter && !withError ? '8px' : '0'};

      p {
        position: absolute;
        right: 0;
        bottom: ${withError ? '0' : '-16px'};
      }

      textarea {
        margin-bottom: ${withCounter ? '8px' : '0'};
      }
    `
  }}
`

const CharCounter = styled.p`
  width: 100%;
  text-align: right;
`

interface ITextAreaWithLabelProps {
  label: string
  id: string
  placeholder?: string
  textAreaValue: string
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>
  charLimit?: number
  saveOnBlur?: () => void
  rows?: number
  hasError?: boolean
  errorMsg?: string
}

export function TextAreaWithLabel({
  label,
  id,
  placeholder,
  textAreaValue,
  setTextAreaValue,
  charLimit,
  saveOnBlur,
  rows,
  hasError,
  errorMsg,
}: ITextAreaWithLabelProps): JSX.Element {
  const [charCount, setCharCount] = useState(textAreaValue?.length || 0)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    if (charLimit) setCharCount(value.length)
    setTextAreaValue(value)
  }

  const showError = hasError || (!!charLimit && charCount > charLimit)

  return (
    <FieldWrapper>
      <StyledTextArea withError={showError} withCounter={!!charLimit}>
        <TextArea
          label={label}
          id={id}
          placeholder={placeholder || 'placeholder'}
          onChange={onChange}
          value={textAreaValue}
          hasError={showError}
          help={errorMsg || `Maximum ${charLimit} characters`}
          onBlur={saveOnBlur ? (): void => saveOnBlur() : null}
          rows={rows || 4}
        />
        {charLimit && <CharCounter>{`${charCount}/${charLimit}`}</CharCounter>}
      </StyledTextArea>
    </FieldWrapper>
  )
}
