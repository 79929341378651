import { useMutation } from '@apollo/client'

import { MUTATION_HIDE_BLOCK } from '~/app/state/mutations'
import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import { useStartPageContext } from '~/modules/pages/state/context'
import type { StartPage, Blocks } from '@buffer-mono/sp-display'
import useTracking from '~/shared/hooks/useTracking/useTracking'

type GetUpdatedBlocksParam = {
  blockId: string
  hide: boolean
  existingBlocks: Blocks
}

export const getUpdatedBlocks = ({
  blockId,
  hide,
  existingBlocks,
}: GetUpdatedBlocksParam) => {
  const newBlockArray = existingBlocks.map((block) => {
    if (block.id === blockId) return { ...block, hidden: hide }
    return block
  })

  return newBlockArray
}

export default function ({ blockId }: { blockId: string }) {
  const { startPageData, setStartPageData } = useStartPageContext()
  const [saveChangesToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_HIDE_BLOCK)

  const { trackBlockHidden, trackBlockMadeVisible } = useTracking()

  const hideOrShowBlock = () => {
    if (blockId && startPageData?.blocks) {
      const blockToHideOrShow = startPageData.blocks.find((block) => {
        if (block.id === blockId) return block
      })

      const hide = !blockToHideOrShow?.hidden

      const newBlockArray = getUpdatedBlocks({
        blockId,
        hide,
        existingBlocks: startPageData.blocks,
      })

      // TODO: We can use Apollo's optimistic response
      // mechanism instead of doing this. Now that we have
      // an "update" function in place, we can pass
      // an optimistic response next! :)
      setStartPageData({
        ...startPageData,
        hasUnPublishedChanges: true,
        blocks: newBlockArray,
      })

      // save changes to DB
      saveChangesToDB({
        variables: {
          blockId,
          hide,
          organizationId: startPageData?.organizationId,
        },
        update: (cache) => {
          const allStartPageData = cache.readQuery<StartPage>({
            query: QUERY_START_PAGE,
            variables: {
              pageId: startPageData?.id,
              organizationId: startPageData?.organizationId,
            },
          })
          cache.writeQuery({
            query: QUERY_START_PAGE,
            data: {
              startPage: {
                ...allStartPageData?.startPage,
                hasUnPublishedChanges: true,
                blocks: newBlockArray,
              },
            },
          })
        },
      })
        .then(() => {
          if (blockToHideOrShow) {
            hide
              ? trackBlockHidden(blockToHideOrShow)
              : trackBlockMadeVisible(blockToHideOrShow)
          }
        })
        .catch((error) => {
          console.log(error)
          // @todo: trigger an error state & refetch data
        })
    }
    // @todo: trigger an error state here
  }

  return {
    hideOrShowBlock,
    response: response ? { ...response } : null,
    mutationError,
    loading,
  }
}
