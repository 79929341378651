import React, { useEffect } from 'react'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'

import { getMigrationHubUrl } from '~/shared/utils/getMigrationHubUrl'
import useTracking from '~/shared/hooks/useTracking/useTracking'

import { Body, MainArea, CheckmarkBlue, ListItem, List, Title } from './styles'

interface ModalProps {
  setOpenModal: (openModal: boolean) => void
}

const CTA = 'startPage-addBlock-Mailchimp-upgrade-1'
const UPGRADE_PATH_NAME = 'migrationHub-upgrade'

export function UpgradePathModalBody({
  setOpenModal,
}: ModalProps): React.JSX.Element {
  const { trackUpgradePathViewed, trackCTAViewed, trackCTAClicked } =
    useTracking()

  const handleFindOutMore = (): void => {
    setOpenModal(false)
    trackCTAClicked({ upgradePathName: UPGRADE_PATH_NAME, cta: CTA })
    window.location.href = getMigrationHubUrl(CTA)
  }

  useEffect(() => {
    trackUpgradePathViewed(UPGRADE_PATH_NAME)
    trackCTAViewed({ upgradePathName: UPGRADE_PATH_NAME, cta: CTA })
  }, [trackCTAViewed, trackUpgradePathViewed])

  return (
    <Body>
      <MainArea>
        {/* TILE AND DESCRIPTION */}
        <Title type="h2">
          Unlock email address
          <br />
          collection in Start Page
        </Title>
        <Text type="p">
          <span
            dangerouslySetInnerHTML={{
              __html:
                'You’re on a <b>legacy Buffer plan</b>. Switch over to the newest version to collect email addresses with Start Page. Plus you’ll receive:',
            }}
          />
        </Text>
        <List>
          <ListItem>
            <CheckmarkBlue />
            No charge for channels you don’t use
          </ListItem>
          <ListItem>
            <CheckmarkBlue />
            Unlimited team members on our team plan
          </ListItem>
          <ListItem>
            <CheckmarkBlue />
            Access to Twitter / X Threads
          </ListItem>
          <ListItem>
            <CheckmarkBlue />
            Access to all new features guaranteed
          </ListItem>
          <ListItem>
            <CheckmarkBlue />
            Access to our beta program
          </ListItem>
          <ListItem>
            <CheckmarkBlue />
            Access to Ideas
          </ListItem>
        </List>
        <Button
          type="primary"
          onClick={(): void => handleFindOutMore()}
          label="Find Out More"
          aria-label="Go to New Plans page"
        />
      </MainArea>
    </Body>
  )
}
