import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 618px;
  display: flex;
  flex-direction: column;

  h3 {
    padding: 24px 16px 16px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`

export const GiphyGallery = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  cursor: pointer;
  margin-left: 24px;
`

export const GifButton = styled.button`
  position: relative;
  width: 105px;
  background: #f5f5f5;
  border-radius: 6px;
  cursor: pointer;
  border: none;
`

export const GiphyImage = styled.img`
  border-radius: 6px;
  width: 105px;
  height: 136px;
`

export const Footer = styled.div`
  display: flex;
  padding: 24px;
  background: #f5f5f5;
  width: 100%;
  justify-content: space-between;
`
