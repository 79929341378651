export const env = {
  VITE_NODE_ENV: import.meta.env.VITE_NODE_ENV,
  VITE_MODE: import.meta.env.VITE_MODE,
  VITE_BACKEND_MODE: import.meta.env.VITE_BACKEND_MODE,
  VITE_URL_API_GATEWAY: import.meta.env.VITE_URL_API_GATEWAY,
  VITE_NAVIGATOR_URL: import.meta.env.VITE_NAVIGATOR_URL,
  VITE_SC_ATTR: import.meta.env.VITE_SC_ATTR,
  VITE_SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  VITE_STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  VITE_CF_WORKER_TRACKING_URL: import.meta.env.VITE_CF_WORKER_TRACKING_URL,
}
