import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 16px;
`

export const ShowMoreLink = styled.button`
  color: black;
  font-weight: 700;
  display: inline-block;
  border: none;
  background-color: transparent;
  cursor: pointer;
`
