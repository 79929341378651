/*
 * FIXME DUPLICATED CODE: We have the same methods in:
 *  - domains/app-shell/src/common/utils
 *  and a similar one in buffer-account
 */

function getUrlEnvModifier(): string {
  const [, envModifier] = window.location.hostname.match(
    /\w+\.(\w+\.)buffer\.com/,
  ) || [null, '']
  return envModifier
}

export function getMigrationHubUrl(cta: string | null): string {
  const envModifier = getUrlEnvModifier()
  const ctaParam = cta ? `/?cta=${cta}` : ``
  return `https://account.${envModifier}buffer.com/new-plans${ctaParam}`
}
