import React from 'react'
import Text from '@bufferapp/ui/Text'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import { useGiphy } from '../../hooks/useGiphy'
import { Wrapper, GiphyGallery, GifButton, GiphyImage, Footer } from './styles'

interface IGiphyModal {
  setOpenModal: (openModal: boolean) => void
  selectGif: (event: any) => void
}

export function GiphyModal({ setOpenModal, selectGif }: IGiphyModal) {
  const { gifs } = useGiphy()
  const { trackGifSelected } = useTracking()

  return (
    <Wrapper>
      <Text type="h3">Choose a GIF</Text>
      <GiphyGallery id="theme-gallery">
        {gifs.map((gif: any, index: number) => (
          <GifButton
            key={`gif-${index}`}
            aria-pressed={false}
            onClick={() => {
              selectGif(gif.src)
              trackGifSelected(gif.src)
              setOpenModal(false)
            }}
          >
            <GiphyImage src={gif.src} alt={gif.alt} />
          </GifButton>
        ))}
      </GiphyGallery>
      <Footer>
        <Text type="p">
          Can't find what you're looking for?{' '}
          <a href="https://giphy.com/" target="_blank" rel="noreferrer">
            Browse Giphy
          </a>
        </Text>
        <Text type="p">Gifs from Giphy</Text>
      </Footer>
    </Wrapper>
  )
}
