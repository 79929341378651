import styled from 'styled-components'

export const DropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 16px 16px;
`

export const SelectWrapper = styled.div`
  width: 50%;

  button {
    justify-content: space-between;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  ul {
    max-height: 177px;
  }
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Left = styled.div`
  svg {
    margin-right: 8px;
  }
`

export const List = styled.ol`
  list-style: none;

  li * {
    list-style: none;
  }
`
