import React, { useEffect, useState } from 'react'
import ButtonLinkIcon from '@bufferapp/ui/Icon/Icons/ButtonLink'

import { InputWithLabel, FieldSet } from '~/shared/components'
import type { LinkBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import catchBlockedLinks from '~/shared/utils/catchBlockedLinks'
import { BLOCK_CATEGORIES } from '../../index'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ESSENTIALS,
  type: 'BUTTON_LINK',
  databaseType: 'StartPageBlockButtonLink',
  icon: {
    Component: ButtonLinkIcon,
    color: '#ff702d',
  },
}

export const addButtonSettings = {
  label: 'Button Link',
  description: 'Links for everything',
}

export const editButtonSettings = {
  label: 'Button Link',
  contentField: 'label',
}

/**
 *
 */
export function BlockForm({ block }: { block: LinkBlock }) {
  const [linkBlock, setLinkBlock] = useState(() => block)
  const [labelValue, setLabelValue] = useState(() => block?.label || '')
  const [linkValue, setLinkValue] = useState(() => block?.url || '')
  const [hasError, setHasError] = useState(false)

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: linkBlock,
  })

  useEffect(() => {
    hasError && setHasError(false)

    const hasBlockedLinks = catchBlockedLinks(linkValue)
    hasBlockedLinks && setHasError(true)
    const canSaveChanges =
      (labelValue !== block.label || linkValue !== block.url) &&
      !hasBlockedLinks

    if (canSaveChanges) {
      setChanges({
        label: labelValue,
        url: linkValue,
      })
    } else setChanges(null)
  }, [labelValue, linkValue])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !linkBlock?.id) {
      setLinkBlock(response.addBlock.block)
    }
  }, [response])

  return (
    <FieldSet>
      <InputWithLabel
        label="Label"
        inputValue={labelValue}
        setInputValue={setLabelValue}
        saveOnBlur={saveOnBlur}
      />
      <InputWithLabel
        label="Link"
        inputValue={linkValue}
        setInputValue={setLinkValue}
        saveOnBlur={saveOnBlur}
        hasError={hasError}
        errorMsg="This content goes against our Terms of Use. Please review the terms before adding your content."
        openInNewTab
      />
    </FieldSet>
  )
}
