import styled from 'styled-components'
import { grayLight, grayDark } from '@bufferapp/ui/style/colors'
import { Camera } from '@bufferapp/ui/Icon'

export const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SmallPreviewWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
  overflow: hidden;
  background-color: ${grayLight};
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  width: 90%;
`

export const StyledImage = styled.img`
  object-fit: cover;
`

export const StyledCameraIcon = styled(Camera)`
  width: 100%;
  color: ${grayDark};
`

export const ErrorWrapper = styled.div`
  margin-top: 8px;
  label {
    display: flex;

    svg {
      margin-right: 8px;
    }
  }
`
