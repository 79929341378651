import React, { useState, useEffect } from 'react'

import { TextAreaWithLabel, FieldSet } from '~/shared/components'

import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import type { MailchimpBlock } from '@buffer-mono/sp-display'
import { validateHTML } from '../../utils'

import { StyledLink, StyledOpenNewIcon, StyledText } from './styles'

const FormArea = ({ block }: { block: MailchimpBlock }): React.JSX.Element => {
  const [hasError, setHasError] = useState(false)
  const [mailchimpBlock, setMailchimpBlock] = useState(() => block)
  const [textAreaValue, setTextAreaValue] = useState(
    () => block?.htmlText || '',
  )

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: mailchimpBlock,
  })

  const saveOnBlur = (): void => {
    setHasError(false)
    if (changes && textAreaValue.trim() !== '') {
      if (validateHTML(textAreaValue)) {
        saveBlock()
      } else {
        setHasError(true)
      }
    }
  }

  useEffect(() => {
    if (textAreaValue.trim() === '') {
      setHasError(false)
    }
    if (textAreaValue !== block.htmlText) {
      setChanges({
        htmlText: textAreaValue,
      })
    } else setChanges(null)
  }, [block.htmlText, setChanges, textAreaValue])

  useEffect(() => {
    if (response && !mailchimpBlock?.id) {
      setMailchimpBlock(response.addBlock.block)
    }
  }, [response, mailchimpBlock?.id])

  return (
    <FieldSet>
      <TextAreaWithLabel
        label="Paste your Mailchimp form’s HTML code below:"
        id="textarea-mailchimp-embedded-form"
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        saveOnBlur={saveOnBlur}
        placeholder="Paste your Mailchimp embedded form here"
        rows={10}
        hasError={hasError}
        errorMsg={hasError ? 'Invalid HTML code, please try again' : undefined}
      />
      <StyledText type="p">
        Read our detailed Step by Step Guide:<br></br>
        <StyledLink
          href="https://support.buffer.com/article/663-how-to-set-up-an-email-signup-form-on-your-start-page?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
          newTab
          fontWeight={700}
        >
          Setting up Mailchimp
          <StyledOpenNewIcon color="black" />
        </StyledLink>
      </StyledText>
    </FieldSet>
  )
}

export default FormArea
