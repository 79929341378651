import React, { useState, useEffect } from 'react'
import { Trash, Hamburger } from '@bufferapp/ui/Icon'
import { InputWithLabel, FieldSet, UploadMedia } from '~/shared/components'
import useImageUpload from '~/app/state/hooks/useImageUpload'
import { IconButton } from '~/modules/pages/components/IconButton'
import catchBlockedLinks from '~/shared/utils/catchBlockedLinks'
import { Layout, Wide, Top, Left } from './styles'

export function ImageLinksGridItem({ link, dispatch }: any) {
  const [linkValue, setLinkValue] = useState(() => link.url || '')
  const [hasError, setHasError] = useState(false)
  const {
    imageUrl: logoUrl,
    setSelectedFile: setSelectedLogoFile,
    imageUploading: logoUploading,
    errorUploading: logoError,
  } = useImageUpload(link?.media?.url || '')
  const [altText, setAltText] = useState(link?.media?.altText || '')

  useEffect(() => {
    hasError && setHasError(false)

    const hasBlockedLinks = catchBlockedLinks(linkValue)
    hasBlockedLinks && setHasError(true)
    const canSaveChanges = linkValue !== link.url && !hasBlockedLinks

    if (canSaveChanges) {
      const newImageDetail = {
        ...link,
        url: linkValue,
      }
      dispatch({ type: 'EDIT', newImageDetail })
    }
  }, [linkValue])

  useEffect(() => {
    if (logoUrl !== link.media.url) {
      const newImageDetail = {
        ...link,
        media: { ...link.media, url: logoUrl },
      }
      dispatch({ type: 'EDIT', newImageDetail })
    }
  }, [logoUrl])

  useEffect(() => {
    if (altText !== link.media.altText) {
      const newImageDetail = {
        ...link,
        media: { ...link.media, altText },
      }
      dispatch({ type: 'EDIT', newImageDetail })
    }
  }, [altText])

  return (
    <FieldSet data-testid="image-list-item">
      <Top>
        <Left>
          <Hamburger color="gray" />
        </Left>
        <div>
          <IconButton
            onClick={() =>
              dispatch({ type: 'DELETE', deleteImageOrder: link.order })
            }
          >
            <Trash color="gray" />
          </IconButton>
        </div>
      </Top>
      <Layout>
        <Wide>
          <UploadMedia
            smallPreview={logoUrl}
            setSelectedFile={setSelectedLogoFile}
            loading={logoUploading}
            errorUploading={logoError}
          />
          <InputWithLabel
            label="Alt Text"
            inputValue={altText}
            setInputValue={setAltText}
          />
          <InputWithLabel
            label="Link"
            inputValue={linkValue}
            setInputValue={setLinkValue}
            placeholder="Add a link to your image"
            hasError={hasError}
            errorMsg="This content goes against our Terms of Use. Please review the terms before adding your content."
          />
        </Wide>
      </Layout>
    </FieldSet>
  )
}
