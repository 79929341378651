import styled, { css } from 'styled-components'
import { grayDark, green, gray } from '@bufferapp/ui/style/colors'
import { fontWeight } from '@bufferapp/ui/style/fonts'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
`

export const DomainURL = styled.p<{ isPublished?: boolean }>`
  font-weight: ${fontWeight};
  font-size: 13px;
  color: ${(props) => (props.isPublished ? grayDark : gray)};
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Status = styled.span<{ isPublished?: boolean }>`
  height: 12px;
  width: 12px;
  background-color: ${(props) => (props.isPublished ? green : grayDark)};
  border: 2px white solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  box-sizing: content-box;

  svg {
    fill: white;
    height: 10px;
    width: 10px;
  }
`

export const Avatar = styled.div`
  position: relative;
`

export const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;

  label {
    margin-bottom: 4px;
  }
`

export const StatusText = styled.p<{ hasSetDomain: boolean }>`
  font-weight: ${fontWeight};
  font-size: 13px;
  color: ${grayDark};
  white-space: nowrap;

  ${(props) =>
    props.hasSetDomain &&
    css`
      &::after {
        content: '•';
        margin: 0 4px;
      }
    `};
`
