import React from 'react'
import Text from '@bufferapp/ui/Text'

import { HelpWrapper } from './styles'

const HelpSection = (): JSX.Element => {
  return (
    <HelpWrapper>
      <Text type="p">
        <b>Why Mailchimp?</b>
      </Text>
      <ul>
        <li>Easy to set up</li>
        <li>Compliance is built in</li>
        <li>Generous free tier, and paid plans that grow with you</li>
      </ul>
    </HelpWrapper>
  )
}

export default HelpSection
