// https://librenepal.com/article/remove-typenames-in-apollo-graphql/
const omitDeep = (obj: any, key: any) => {
  const keys = Object.keys(obj)
  const newObj: any = {}
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) newObj[i] = val
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null)
        newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })
  return newObj
}

const omitDeepArrayWalk = (arr: any, key: any) =>
  arr.map((val: any) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    if (typeof val === 'object') return omitDeep(val, key)
    return val
  })

// we need to clean the typename from the existing objects
// so we can manipulate it and send it back in the mutation
export const cleanTypename = (obj: any) => {
  const copy = { ...obj }
  return omitDeep(copy, '__typename')
}
