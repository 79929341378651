import React from 'react'
import Text from '@bufferapp/ui/Text'
import * as Icons from '@bufferapp/ui/Icon'

import { Wrapper, Link, CopyWrapper, SubHeading } from './styles'

export const SETTINGS_PATHS: { [index: string]: string } = {
  Instagram: 'https://www.instagram.com/accounts/edit/',
  Tiktok: 'https://www.tiktok.com/setting',
  Twitter: 'https://twitter.com/settings/profile',
  Facebook: 'https://web.facebook.com/',
}

/**
 *
 */
function LinkCopied() {
  return (
    <Wrapper>
      <Text type="h3">Link Copied 🎉</Text>
      <SubHeading type="p">
        Get more views by adding your link to your socials
      </SubHeading>
      {Object.keys(SETTINGS_PATHS).map((key, index) => {
        const IconComponent = Icons[key] || Icons.Placeholder
        const ArrowComponent = Icons.ArrowRight

        return (
          <Link
            href={SETTINGS_PATHS[key]}
            key={index}
            target="_blank"
            rel="noopener"
          >
            <CopyWrapper>
              <IconComponent />
              <Text type="p">Open {key} settings</Text>
            </CopyWrapper>
            <ArrowComponent />
          </Link>
        )
      })}
    </Wrapper>
  )
}

export default LinkCopied
