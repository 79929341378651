import React from 'react'
import styled from 'styled-components'
import Button from '@bufferapp/ui/Button'
import { Copy } from '@bufferapp/ui/Icon'

import { BASE_URL } from '~/shared/constants'
import { useStartPageContext } from '~/modules/pages/state/context'
import useTracking from '~/shared/hooks/useTracking/useTracking'

const Wrapper = styled.div`
  width: 112px;
`

/**
 *
 */
function CopyLinkButton({
  primary,
  onLinkCopied,
}: {
  primary?: boolean
  onLinkCopied: () => void
}) {
  const { startPageData, hasDomain } = useStartPageContext()
  const { trackURLCopied } = useTracking()
  const { domain } = startPageData || {}

  const onClick = () => {
    const FULL_URL = `https://${domain}${BASE_URL}`
    navigator.clipboard.writeText(FULL_URL)
    trackURLCopied(FULL_URL)
    onLinkCopied()
  }

  return hasDomain && navigator?.clipboard ? (
    <Wrapper>
      <Button
        type={primary ? 'primary' : 'secondary'}
        icon={primary ? null : <Copy />}
        onClick={onClick}
        label="Copy Link"
        fullWidth
      />
    </Wrapper>
  ) : null
}

export default CopyLinkButton
