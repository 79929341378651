import React, { useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import SimpleModal from '@bufferapp/ui/SimpleModal'

export default function useModal() {
  const [openModal, setOpenModal] = useState(false)

  const Modal = useCallback(
    ({ children }: { children: React.ReactNode }) => {
      const container: Element = document.getElementById(
        'modal-wrapper',
      ) as Element

      return createPortal(
        <SimpleModal closeAction={() => setOpenModal(false)}>
          {children}
        </SimpleModal>,
        container,
        'modal',
      )
    },
    [openModal, setOpenModal],
  )

  return {
    openModal,
    setOpenModal,
    Modal,
  }
}
