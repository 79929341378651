import { useState } from 'react'

import useEditBlock from '~/app/state/hooks/useEditBlock'
import useAddBlock from '~/app/state/hooks/useAddBlock'
import type { SocialLink, ImageGridImage } from '@buffer-mono/sp-display'

export type NewMediaProps = {
  file?: File
  s3SignedUrl: string
}

export type ChangesState = {
  media?: {
    url?: string
    altText?: string
  }
  label?: string
  url?: string
  caption?: string
  links?: SocialLink[] | ImageGridImage[]
  title?: string
  description?: string
  logoMedia?: { url?: string; altText?: string }
  backgroundMedia?: { url?: string }
  text?: string
  htmlText?: string
  htmlJson?: string
  layout?: string
} | null
export type SetChangesState = React.Dispatch<React.SetStateAction<ChangesState>>

type UseSaveBlockProps = {
  block: any
}

export default function ({ block }: UseSaveBlockProps) {
  const [changes, setChanges] = useState<ChangesState>(null)
  const isEditing = block?.id

  const {
    editBlock,
    response: editResponse,
    mutationError: editError,
    loading: editLoading,
  } = useEditBlock({ blockId: block?.id, changes })

  const {
    addBlock,
    response: addResponse,
    mutationError: addError,
    loading: addLoading,
  } = useAddBlock({ block, changes })

  return {
    saveBlock: isEditing ? editBlock : addBlock,
    response: isEditing ? editResponse : addResponse,
    mutationError: isEditing ? editError : addError,
    loading: isEditing ? editLoading : addLoading,
    changes,
    setChanges,
  }
}
