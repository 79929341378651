import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { grayLighter, grayDarker } from '@bufferapp/ui/style/colors'
import Dropdown from '~/shared/components/Dropdown'

export const Title = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: ${grayDarker};
  padding: 16px;
  border-bottom: 1px solid ${grayLighter};
`

export const OptionsWrapper = styled.div`
  max-height: 245px;
  overflow: scroll;
`

export const OptionWrapper = styled(Dropdown.Option)<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.selected ? 'space-between' : 'flex-start'};
  align-items: center;
  padding: 12px 16px;
  background-color: ${(props) => (props.selected ? grayLighter : 'none')};
  border-bottom: 1px solid ${grayLighter};
`

export const IconWrapper = styled.span`
  svg {
    fill: ${grayDarker};
    color: ${grayDarker};
  }
`

export const CTAWrapper = styled.div<{ withBorder: boolean }>`
  padding: 16px;
  border-top: ${(props) =>
    props.withBorder ? `1px solid ${grayLighter}` : 'none'};
`

export const WarningText = styled(Text)`
  font-size: 12px;
  line-height: 14px;
  color: ${grayDarker};
`
