/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable react/require-default-props */
import React from 'react'
import Bugsnag from '@bugsnag/js'
import { Trash, ArrowLeft, Hide, View } from '@bufferapp/ui/Icon'
import Text from '@bufferapp/ui/Text'

import * as BlockForms from '~/modules/pages/components/BlockForm'
import { DeleteBlockModal } from '~/shared/components'
import useModal from '~/shared/hooks/useModal'
import { componentMap } from '@buffer-mono/sp-display'

import { Header, HeaderActions, Wrapper, TransparentButton } from './styles'

export const labels: { [key: string]: string } = {
  StartPageBlockIconLinks: 'Social Links',
  StartPageBlockSubHeading: 'Sub Heading',
  StartPageBlockHeader: 'Header',
  StartPageBlockText: 'Text',
  StartPageBlockButtonLink: 'Link',
  StartPageBlockImageLink: 'Image',
  StartPageBlockVideo: 'YouTube Video',
  StartPageBlockSpotify: 'Spotify Music',
  StartPageBlockImageLinksGrid: 'Image Links Grid',
  StartPageBlockFeed: 'Updates',
  StartPageBlockFeedPost: 'Feed Post',
  StartPageBlockMailchimp: 'Mailchimp',
}

interface EditPanelProps {
  block?: any
  onReturnClick: () => void
  deleteBlock?: () => void
  hideOrShowBlock?: () => void
}

function EditPanel({
  block,
  onReturnClick,
  deleteBlock,
  hideOrShowBlock,
}: EditPanelProps): JSX.Element | null {
  const { openModal, setOpenModal, Modal } = useModal()

  if (!block) return null

  const shouldDisplayDeleteAction =
    deleteBlock && block?.__typename !== 'StartPageBlockHeader'
  const shouldDisplayHideOrShowAction =
    hideOrShowBlock && block?.__typename !== 'StartPageBlockHeader'

  const componentName = block ? componentMap[block.__typename] : null
  if (!componentName) {
    Bugsnag.notify(
      new Error(`[EditPanel] Block is empty or there is no component for it`),
    )
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Form = (BlockForms as any)[componentName]

  return (
    <Wrapper>
      <Header>
        <div>
          <button
            type="button"
            onClick={onReturnClick}
            aria-label="Go back to layout view"
          >
            <ArrowLeft />
          </button>
          <Text htmlFor="" type="help">
            {block?.id ? 'Edit' : 'Add'} {labels[block.__typename]}
          </Text>
        </div>
        <HeaderActions>
          {shouldDisplayDeleteAction && (
            <TransparentButton
              onClick={(): void => {
                setOpenModal(true)
              }}
            >
              <Trash title="delete block" />
            </TransparentButton>
          )}
          {shouldDisplayHideOrShowAction && (
            <TransparentButton
              onClick={(): void => {
                hideOrShowBlock()
              }}
            >
              {block?.hidden ? (
                <Hide title="hide block" />
              ) : (
                <View title="hide block" />
              )}
            </TransparentButton>
          )}
        </HeaderActions>
      </Header>
      <Form block={block} />

      {openModal && deleteBlock && (
        <Modal>
          <DeleteBlockModal
            setOpenModal={setOpenModal}
            deleteBlock={deleteBlock}
            onReturnClick={onReturnClick}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

export default EditPanel
