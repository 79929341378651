import styled from 'styled-components'

export const HelpWrapper = styled.div`
  margin: 30px 0;
  color: black;

  ul {
    margin-top: 15px;

    li {
      margin-left: 30px;
      list-decoration: bullet;
      font-size: 14px;
      line-height: 20px;
    }
  }
`
