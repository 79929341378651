import { useMutation } from '@apollo/client'
import { MUTATION_SAVE_THEME } from '~/app/state/mutations'
import { useStartPageContext } from '~/modules/pages/state/context'
import type { IStartPageTheme } from '@buffer-mono/sp-display'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import { cleanTypename } from '~/shared/utils/cleanTypename'

export interface IChanges {
  id: string
  backgroundColor: string
  textColor: string
}

export default function () {
  const { startPageData } = useStartPageContext()
  const { trackThemeSelected } = useTracking()
  const [saveThemeToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_SAVE_THEME)

  const saveTheme = (theme: IStartPageTheme | null) => {
    if (theme === null) {
      return
    }

    saveThemeToDB({
      variables: {
        organizationId: startPageData?.organizationId,
        pageId: startPageData?.id,
        theme: cleanTypename(theme),
      },
      update(cache, { data: { saveTheme } }) {
        trackThemeSelected(theme.id)
      },
    })
  }

  return {
    saveTheme,
    response: response ? { ...response } : null,
    mutationError,
    loading,
  }
}
