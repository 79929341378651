import React, { useState, useEffect, useCallback } from 'react'
import { Plate } from '@udecode/plate'
import Text from '@bufferapp/ui/Text'
import debounce from 'lodash/debounce'

import { editableProps } from './config/editableProps'
import { createMyPlugins, type RichTextValue } from './config/types'

import { Toolbar } from './components/Toolbar'
import GetFormattedHTML from './components/GetFormattedHTML'
import ToolbarButtons from './components/Toolbar/ToolBarButtons'
import { plateUI } from './config/plateUI'
import { formattingPlugins } from './config/formattingPlugins'
import { BlockWrapper, EditorWrapper, toolbarStyle } from './styles'

interface IRichTextEditorProps {
  initialValue: string
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>
  setTextEditorValue: React.Dispatch<React.SetStateAction<string>>
  setSerializedHtml: React.Dispatch<React.SetStateAction<string>>
  saveOnBlur?: () => void
  savedText: string
}

export function RichTextEditor({
  setTextEditorValue,
  saveOnBlur,
  setSerializedHtml,
  initialValue,
}: IRichTextEditorProps) {
  const [generatedHtml, setGeneretadeHtml] = useState('')
  const [value, setValue] = useState(initialValue)

  const plugins = createMyPlugins([...formattingPlugins], {
    components: plateUI,
  })

  const updateEditorValue = (value: any) => {
    setValue(value)
  }

  const debouncedUpdateEditorValue = debounce(updateEditorValue, 100)

  const handleEditorChange = useCallback((value) => {
    debouncedUpdateEditorValue(value)
  }, [])

  useEffect(() => {
    setSerializedHtml(generatedHtml)
    setTextEditorValue(value)
  }, [value])

  return (
    <BlockWrapper>
      <Text type="p">Text</Text>

      <EditorWrapper onBlur={saveOnBlur ? () => saveOnBlur() : undefined}>
        <Plate<RichTextValue>
          editableProps={editableProps}
          initialValue={JSON.parse(initialValue)}
          plugins={plugins}
          onChange={(value) => {
            handleEditorChange(JSON.stringify(value))
          }}
        >
          <GetFormattedHTML setGeneretadeHtml={setGeneretadeHtml} />
        </Plate>

        <Toolbar styles={{ root: toolbarStyle }}>
          <ToolbarButtons />
        </Toolbar>
      </EditorWrapper>
    </BlockWrapper>
  )
}
