import styled from 'styled-components'
import { tealLightest, tealLighter } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  margin: 0px 16px;
  background-color: ${tealLightest};
  border-radius: 4px;
  border: 1px solid ${tealLighter};
`

export const TipButton = styled.button`
  width: 100%;
  padding: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: none;
  text-align: left;
`

export const TipContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const TipText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 0px 12px;
`

export const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`

export const Description = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 0px 16px 16px;

  ol {
    list-style-position: inside;
  }

  video {
    width: 100%;
    margin-top: 16px;
    border-radius: 8px;
  }
`
