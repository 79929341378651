import React from 'react'
import {
  HeadingToolbar,
  type ToolbarProps,
  withPlateEventProvider,
} from '@udecode/plate'

export const Toolbar = withPlateEventProvider((props: ToolbarProps) => (
  <HeadingToolbar {...props} />
))
