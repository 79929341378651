import { gql } from '@apollo/client'

export const MUTATION_HIDE_BLOCK = gql`
  mutation hideOrShowBlock(
    $blockId: String!
    $hide: Boolean
    $organizationId: String!
  ) {
    hideOrShowBlock(
      blockId: $blockId
      hide: $hide
      organizationId: $organizationId
    ) {
      ... on MutationResponse {
        code
        success
        message
      }
      ... on BlockMutationSuccess {
        block {
          id
          pageId
          order
          hidden
          ... on StartPageBlockHeader {
            backgroundMedia {
              url
              altText
            }
            logoMedia {
              url
              altText
            }
            title
            description
            overlay
            layout
          }
          ... on StartPageBlockIconLinks {
            links {
              url
              icon
              order
            }
          }
          ... on StartPageBlockSubHeading {
            text
          }
          ... on StartPageBlockText {
            text
            htmlText
            htmlJson
          }
          ... on StartPageBlockButtonLink {
            label
            url
          }
          ... on StartPageBlockVideo {
            media {
              url
              altText
            }
          }
          ... on StartPageBlockImageLink {
            caption
            url
            media {
              url
              altText
            }
          }
          ... on StartPageBlockSpotify {
            media {
              url
            }
            layout
          }
          ... on StartPageBlockImageLinksGrid {
            links {
              url
              media {
                url
                altText
              }
              order
              id
            }
          }
          ... on StartPageBlockFeed {
            title
            children {
              id
              order
              hidden
              createdAt
              ... on StartPageBlockFeedPost {
                caption
                title
                text
                label
                url
                media {
                  url
                  altText
                }
              }
            }
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
        }
      }
    }
  }
`

export const MUTATION_EDIT_BLOCK = gql`
  mutation editBlock(
    $blockId: String!
    $data: BlockDataInput
    $organizationId: String!
  ) {
    editBlock(blockId: $blockId, data: $data, organizationId: $organizationId) {
      ... on MutationResponse {
        code
        success
        message
      }
      ... on BlockMutationSuccess {
        block {
          id
          pageId
          order
          hidden
          ... on StartPageBlockHeader {
            backgroundMedia {
              url
              altText
            }
            logoMedia {
              url
              altText
            }
            title
            description
            overlay
            layout
          }
          ... on StartPageBlockIconLinks {
            links {
              url
              icon
              order
            }
          }
          ... on StartPageBlockSubHeading {
            text
          }
          ... on StartPageBlockText {
            text
            htmlText
            htmlJson
          }
          ... on StartPageBlockButtonLink {
            label
            url
          }
          ... on StartPageBlockVideo {
            media {
              url
              altText
            }
          }
          ... on StartPageBlockImageLink {
            caption
            url
            media {
              url
              altText
            }
          }
          ... on StartPageBlockSpotify {
            media {
              url
            }
            layout
          }
          ... on StartPageBlockImageLinksGrid {
            links {
              url
              media {
                url
                altText
              }
              order
              id
            }
          }
          ... on StartPageBlockFeed {
            title
            children {
              id
              order
              hidden
              createdAt
              ... on StartPageBlockFeedPost {
                caption
                title
                text
                label
                url
                media {
                  url
                  altText
                }
              }
            }
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
        }
      }
    }
  }
`

export const MUTATION_DELETE_BLOCK = gql`
  mutation deleteBlock($blockId: String!, $organizationId: String!) {
    deleteBlock(blockId: $blockId, organizationId: $organizationId) {
      ... on MutationResponse {
        code
        success
        message
      }
      ... on BlocksMutationSuccess {
        blocks {
          id
          pageId
          order
          hidden
          ... on StartPageBlockHeader {
            backgroundMedia {
              url
              altText
            }
            logoMedia {
              url
              altText
            }
            title
            description
            overlay
            layout
          }
          ... on StartPageBlockIconLinks {
            links {
              url
              icon
              order
            }
          }
          ... on StartPageBlockSubHeading {
            text
          }
          ... on StartPageBlockText {
            text
            htmlText
            htmlJson
          }
          ... on StartPageBlockButtonLink {
            label
            url
          }
          ... on StartPageBlockVideo {
            media {
              url
              altText
            }
          }
          ... on StartPageBlockImageLink {
            caption
            url
            media {
              url
              altText
            }
          }
          ... on StartPageBlockSpotify {
            media {
              url
            }
            layout
          }
          ... on StartPageBlockImageLinksGrid {
            links {
              url
              media {
                url
                altText
              }
              order
              id
            }
          }
          ... on StartPageBlockFeed {
            title
            children {
              id
              order
              hidden
              createdAt
              ... on StartPageBlockFeedPost {
                caption
                title
                text
                label
                url
                media {
                  url
                  altText
                }
              }
            }
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
        }
      }
    }
  }
`

export const MUTATION_PUBLISH = gql`
  mutation publishStartPage(
    $organizationId: String!
    $pageId: String!
    $data: PublishStartPageInput
  ) {
    publishStartPage(
      organizationId: $organizationId
      pageId: $pageId
      data: $data
    ) {
      ... on StartPageMutationSuccess {
        startPage {
          id
          domain
          isPublished
        }
      }
      ... on MutationResponse {
        code
        success
        message
      }
      ... on CommonError {
        code
        message
      }
    }
  }
`

export const MUTATION_ADD_BLOCK = gql`
  mutation addBlock(
    $config: BlockConfigInput
    $organizationId: String!
    $pageId: String!
  ) {
    addBlock(
      config: $config
      pageId: $pageId
      organizationId: $organizationId
    ) {
      ... on MutationResponse {
        code
        success
        message
      }
      ... on BlockMutationSuccess {
        block {
          id
          pageId
          order
          hidden
          ... on StartPageBlockHeader {
            backgroundMedia {
              url
              altText
            }
            logoMedia {
              url
              altText
            }
            title
            description
            overlay
            layout
          }
          ... on StartPageBlockIconLinks {
            links {
              url
              icon
              order
            }
          }
          ... on StartPageBlockSubHeading {
            text
          }
          ... on StartPageBlockText {
            text
            htmlText
            htmlJson
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
          ... on StartPageBlockButtonLink {
            label
            url
          }
          ... on StartPageBlockVideo {
            media {
              url
              altText
            }
          }
          ... on StartPageBlockImageLink {
            caption
            url
            media {
              url
              altText
            }
          }
          ... on StartPageBlockSpotify {
            media {
              url
            }
            layout
          }
          ... on StartPageBlockImageLinksGrid {
            links {
              url
              media {
                url
                altText
              }
              order
              id
            }
          }
          ... on StartPageBlockFeed {
            title
            children {
              id
              order
              hidden
              createdAt
              ... on StartPageBlockFeedPost {
                caption
                title
                text
                label
                url
                media {
                  url
                  altText
                }
              }
            }
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
        }
      }
    }
  }
`

export const MUTATION_REORDER_BLOCK = gql`
  mutation reOrderBlock(
    $blockId: String!
    $order: Int!
    $organizationId: String!
  ) {
    reOrderBlock(
      blockId: $blockId
      order: $order
      organizationId: $organizationId
    ) {
      ... on MutationResponse {
        code
        success
      }
      ... on BlocksMutationSuccess {
        blocks {
          id
          pageId
          order
          hidden
          ... on StartPageBlockHeader {
            backgroundMedia {
              url
              altText
            }
            logoMedia {
              url
              altText
            }
            title
            description
            overlay
            layout
          }
          ... on StartPageBlockIconLinks {
            links {
              url
              icon
              order
            }
          }
          ... on StartPageBlockSubHeading {
            text
          }
          ... on StartPageBlockText {
            text
            htmlText
            htmlJson
          }
          ... on StartPageBlockButtonLink {
            label
            url
          }
          ... on StartPageBlockVideo {
            media {
              url
              altText
            }
          }
          ... on StartPageBlockImageLink {
            caption
            url
            media {
              url
              altText
            }
          }
          ... on StartPageBlockSpotify {
            media {
              url
            }
            layout
          }
          ... on StartPageBlockImageLinksGrid {
            links {
              url
              media {
                url
                altText
              }
              order
              id
            }
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
        }
      }
    }
  }
`

export const MUTATION_SAVE_THEME = gql`
  mutation saveTheme(
    $organizationId: String!
    $pageId: String!
    $theme: StartPageThemeInput
  ) {
    saveTheme(organizationId: $organizationId, pageId: $pageId, theme: $theme) {
      ... on StartPageMutationSuccess {
        startPage {
          id
          domain
          hasUnPublishedChanges
          theme {
            id
            globalStyles {
              backgroundColor
              textColor
            }
            blockStyles {
              blockType
              backgroundColor
              secondaryBackgroundColor
              textColor
              secondaryTextColor
              textAlign
              corners
              fontFamily
              secondaryFontFamily
              border
            }
          }
        }
      }
      ... on MutationResponse {
        code
        success
        message
      }
      ... on CommonError {
        code
        message
      }
    }
  }
`

export const MUTATION_SAVE_NAME = gql`
  mutation saveStartPageName(
    $organizationId: String!
    $pageId: String!
    $name: String!
  ) {
    saveStartPageName(
      organizationId: $organizationId
      pageId: $pageId
      name: $name
    ) {
      ... on StartPageMutationSuccess {
        startPage {
          id
          domain
          title
          hasUnPublishedChanges
          theme {
            id
            globalStyles {
              backgroundColor
              textColor
            }
            blockStyles {
              blockType
              backgroundColor
              textColor
              textAlign
              corners
              fontFamily
              secondaryFontFamily
              border
            }
          }
        }
      }
      ... on MutationResponse {
        code
        success
        message
      }
      ... on CommonError {
        code
        message
      }
    }
  }
`

export const MUTATION_CREATE_START_PAGE = gql`
  mutation createStartPage(
    $organizationId: String!
    $config: StartPageConfigInput
  ) {
    createStartPage(organizationId: $organizationId, config: $config) {
      ... on StartPageMutationSuccess {
        code
        success
        startPage {
          id
          domain
          hasUnPublishedChanges
          organizationId
          theme {
            id
            globalStyles {
              backgroundColor
              textColor
            }
            blockStyles {
              blockType
              backgroundColor
              secondaryBackgroundColor
              textColor
              secondaryTextColor
              textAlign
              corners
              fontFamily
              secondaryFontFamily
              border
            }
          }
          blocks {
            id
            pageId
            order
            ... on StartPageBlockHeader {
              backgroundMedia {
                url
                altText
              }
              logoMedia {
                url
                altText
              }
              title
              description
              overlay
            }
            ... on StartPageBlockIconLinks {
              links {
                url
                icon
                order
              }
            }
            ... on StartPageBlockSubHeading {
              text
            }
            ... on StartPageBlockText {
              text
              htmlText
              htmlJson
            }
            ... on StartPageBlockButtonLink {
              label
              url
            }
            ... on StartPageBlockVideo {
              media {
                url
                altText
              }
            }
            ... on StartPageBlockImageLink {
              caption
              url
              media {
                url
                altText
              }
            }
            ... on StartPageBlockSpotify {
              media {
                url
              }
              layout
            }
            ... on StartPageBlockImageLinksGrid {
              links {
                url
                media {
                  url
                  altText
                }
                order
                id
              }
            }
            ... on StartPageBlockFeed {
              title
              children {
                id
                order
                hidden
                createdAt
                ... on StartPageBlockFeedPost {
                  caption
                  title
                  text
                  label
                  url
                  media {
                    url
                    altText
                  }
                }
              }
            }
            ... on StartPageBlockMailchimp {
              htmlText
            }
          }
        }
      }
      ... on MutationResponse {
        code
        success
        message
      }
      ... on CommonError {
        code
        message
        error
      }
    }
  }
`
