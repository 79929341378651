import styled, { css } from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const StyledText = styled(Text)`
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${grayDarker};
  margin: 4px 0px 8px;
`

export const LighterStyledText = styled(Text)`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: ${grayDarker};
  margin: 4px 0px 8px;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const Button = styled.button<{ selected: boolean }>`
  background: #f5f5f5;
  border-radius: 6px;
  cursor: pointer;
  border: none;

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 0 4px #acb9fd;
    `}
`

export const Image = styled.img`
  border-radius: 6px;
  max-width: 100%;
`
