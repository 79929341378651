import React, { useState, useEffect } from 'react'
import Spotify from '@bufferapp/ui/Icon/Icons/Spotify'

import { InputWithLabel, FieldSet } from '~/shared/components'
import { type SpotifyBlock, getSpotifyEmbed } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import { BLOCK_CATEGORIES } from '../../index'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.MEDIA,
  type: 'SPOTIFY',
  databaseType: 'StartPageBlockSpotify',
  icon: {
    Component: Spotify,
    color: '#39bf43',
  },
}

export const addButtonSettings = {
  label: 'Spotify Music',
  description: 'Embed your favorite music',
}

/**
 *
 */
export const editButtonSettings = {
  label: 'Spotify Music',
  contentField: null,
}

/**
 *
 */
export function BlockForm({ block }: { block: SpotifyBlock }) {
  const [spotifyBlock, setSpotifyBlock] = useState(() => block)
  const [linkValue, setLinkValue] = useState(() => block?.media?.url || '')
  const [hasError, setHasError] = useState(false)

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: spotifyBlock,
  })

  useEffect(() => {
    if (linkValue) {
      try {
        getSpotifyEmbed(linkValue)
        setHasError(false)
      } catch (error) {
        setHasError(true)
      }
    }

    if (linkValue !== block?.media?.url) {
      setChanges({
        media: {
          url: linkValue,
        },
      })
    } else setChanges(null)
  }, [linkValue])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !spotifyBlock?.id) {
      setSpotifyBlock(response.addBlock.block)
    }
  }, [response])

  return (
    <FieldSet>
      <InputWithLabel
        label="Spotify Music URL"
        inputValue={linkValue}
        setInputValue={setLinkValue}
        saveOnBlur={saveOnBlur}
        hasError={hasError}
        errorMsg="Invalid Link, try a different Spotify link."
      />
    </FieldSet>
  )
}
