import React from 'react'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'
import {
  Wrapper,
  QuestionBox,
  ButtonContainer,
} from '~/shared/components/DeleteBlockModal/styles'
import PageOption from '~/modules/pages/components/PageSelector/PageOption'
import { getStartPageInfo } from '~/modules/pages/components/PageSelector'
import { useStartPageContext } from '~/modules/pages/state/context'
import styled from 'styled-components'

/**
 *
 */
const OptionWrapper = styled.div`
  background-color: white;
  width: 80%;
  border-radius: 6px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 8px;
`

const Underline = styled.span`
  text-decoration: underline;
`

/**
 *
 */
export function ConfirmationModal({
  onContinue,
  onCancel,
}: {
  onContinue: () => void
  onCancel: (openModal: boolean) => void
}) {
  const { startPageData } = useStartPageContext()

  const startPageInfo = startPageData && getStartPageInfo(startPageData)
  if (!startPageInfo) return null

  const { domain, logoUrl, logoAlt, title, hasSetDomain } = startPageInfo

  return (
    <Wrapper>
      <Text type="h3">You're unpublishing your page</Text>
      <QuestionBox>
        <OptionWrapper>
          <PageOption
            domain={domain}
            logoUrl={logoUrl}
            logoAlt={logoAlt}
            title={title}
            isPublished
            hasSetDomain={hasSetDomain}
          />
        </OptionWrapper>
        <Text type="p">
          Are you sure you want to unpublish your page? Once unpublished, the
          page can't be accessed using the link{' '}
          <Underline>{domain}.start.page</Underline>
          <br />
          <br />
          Your changes will remain here and you can publish again later
        </Text>
      </QuestionBox>
      <ButtonContainer>
        <Button type="text" onClick={onCancel} label="Cancel" />
        <Button
          type="danger"
          onClick={onContinue}
          label="Confirm and unpublish"
        />
      </ButtonContainer>
    </Wrapper>
  )
}
