import type { ImageGridImage } from '@buffer-mono/sp-display'

interface IReducerState {
  links: ImageGridImage[] | []
  needsToBeSaved: boolean
}

interface IReorderInfo {
  startIndex: number
  endIndex: number
}

export type IReducerAction =
  | { type: 'ADD'; newImage: ImageGridImage }
  | { type: 'EDIT'; newImageDetail: ImageGridImage }
  | { type: 'REORDER'; reorderInfo: IReorderInfo }
  | { type: 'DELETE'; deleteImageOrder: number }

const ImageLinksGridReducer = (
  state: IReducerState,
  action: IReducerAction,
) => {
  switch (action.type) {
    case 'ADD': {
      return {
        ...state,
        links: [...state.links, action.newImage],
        needsToBeSaved: false,
      }
    }
    case 'EDIT': {
      const updatedArray = state.links.map((item, index) => {
        if (item.order === action.newImageDetail.order) {
          return {
            ...action.newImageDetail,
          }
        }
        return {
          ...item,
        }
      })

      return {
        ...state,
        links: updatedArray,
        needsToBeSaved: true,
      }
    }
    case 'REORDER': {
      const newBlockArray = Array.from(state.links)
      const [removed] = newBlockArray.splice(action.reorderInfo.startIndex, 1)
      newBlockArray.splice(action.reorderInfo.endIndex, 0, removed)
      const updatedOrderArray = newBlockArray.map((item, index) => ({
        ...item,
        order: index,
      }))

      return {
        ...state,
        links: updatedOrderArray,
        needsToBeSaved: true,
      }
    }
    case 'DELETE': {
      const newBlockArray = Array.from(state.links)
      newBlockArray.splice(action.deleteImageOrder, 1)
      const newLinks = newBlockArray.map((item, index) => ({
        ...item,
        order: index,
      }))

      return {
        ...state,
        links: newLinks,
        needsToBeSaved: true,
      }
    }
    default:
      throw new Error("That type doesn't exist")
  }
}

export default ImageLinksGridReducer
