import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

import {
  white,
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
} from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${white};
  padding: 28px;
  width: 370px;
  color: ${grayDarker};

  :last-child {
    margin-bottom: 0;
  }
`

export const SubHeading = styled(Text)`
  margin: 4px 0px;
`

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border: 1px solid ${grayLight};
  border-radius: 6px;
  cursor: pointer;
  margin-top: 12px;
  text-decoration: none;
  color: ${grayDarker};

  :hover {
    background-color: ${grayLighter};
  }
`

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${grayDarker};

  svg {
    color: ${grayDark};
  }

  & > * {
    margin-right: 8px;
  }
`
