import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin: 20px 0px;

  div {
    display: flex;
    align-items: center;
    button {
      border: none;
      background-color: transparent;
      margin-right: 8px;
      cursor: pointer;
      display: flex;
    }
  }
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: right;
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TransparentButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    fill: #636363;
  }
`
