import React from 'react'
import Button from '@bufferapp/ui/Button'
import type { IReducerAction } from './ImageLinksGridReducer'
import { ButtonWrapper } from './styles'

export function Header({
  linksLength,
  dispatch,
}: {
  linksLength: number
  dispatch: React.Dispatch<IReducerAction>
}) {
  const addNewImage = () => {
    const newImage = {
      url: '',
      media: {
        url: '',
        altText: '',
      },
      order: linksLength === 0 ? 0 : linksLength,
      id: Math.random().toString(), // adding a temp id here so React can keep unique keys on block creation, once saved the DB will assing a UUID
    }
    dispatch({ type: 'ADD', newImage })
  }

  return (
    <ButtonWrapper>
      <Button
        type="secondary"
        fullWidth
        label="Add new Image"
        onClick={() => addNewImage()}
      />
    </ButtonWrapper>
  )
}
