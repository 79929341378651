import { useLazyQuery } from '@apollo/client'
import type { IStartPageTheme } from '@buffer-mono/sp-display'
import { QUERY_THEMES } from '../../../app/state/queries'

export function useThemes() {
  const [queryThemes, { data, error, loading }] = useLazyQuery(QUERY_THEMES)

  return {
    queryThemes,
    themes: (data?.startPageThemes?.themes as [IStartPageTheme]) || null,
    loading: loading || (!data && !error),
    error,
  }
}
