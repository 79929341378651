import React from 'react'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

export const EmptyListWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 145px;
  margin: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function EmptyList({ children }: { children: React.ReactNode }) {
  return (
    <EmptyListWrapper>
      <Text type="p">{children}</Text>
    </EmptyListWrapper>
  )
}
