import React from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DragUpdate,
} from 'react-beautiful-dnd'
import type { SocialLink } from '@buffer-mono/sp-display'
import { List } from './styles'
import { IconLinkItem } from './IconLinkItem'
import type { IReducerAction } from './IconLinksReducer'

export function IconLinksList({
  links,
  dispatch,
  saveOnBlur,
}: {
  links: SocialLink[]
  dispatch: React.Dispatch<IReducerAction>
  saveOnBlur: () => void
}) {
  const onDragEnd = (result: DragUpdate): void => {
    const { destination, source } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }
    const reorderInfo = {
      startIndex: source.index,
      endIndex: destination.index,
    }
    dispatch({ type: 'REORDER', reorderInfo })
  }
  return (
    <div data-testid="icons-list">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableIconsList">
          {(provided): JSX.Element => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {links.length > 0 &&
                links.map((link: SocialLink, index: number) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided): JSX.Element => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <IconLinkItem
                          link={link}
                          key={index + link.icon}
                          dispatch={dispatch}
                          saveOnBlur={saveOnBlur}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
