import styled from 'styled-components'
import { white, gray, grayDarker } from '@bufferapp/ui/style/colors'

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 8px 16px;
`

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${grayDarker};
    color: ${grayDarker};
  }

  svg:hover {
    fill: ${grayDarker};
    color: ${grayDarker};
  }
`

export const ContentWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0px 16px;
  min-width: 275px;
  background-color: ${white};
  border: 1px solid ${gray};
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
`

export const StyledOption = styled.li`
  list-style: none;
  cursor: pointer;
`

export const StyledList = styled.ul`
  max-height: 500px;
`
