import React from 'react'
import styled from 'styled-components'

import { fontFamily, fontWeightBold } from '@bufferapp/ui/style/fonts'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const StyledHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: ${grayDarker};
  flex-shrink: 0;

  h2 {
    font-family: ${fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: ${fontWeightBold};
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 16px;
  }
`

function Header({ children }: { children: React.ReactNode }) {
  return <StyledHeader>{children}</StyledHeader>
}

export default Header
