import React from 'react'
import Text from '@bufferapp/ui/Text'
import Select from '@bufferapp/ui/Select'
import * as Icons from '@bufferapp/ui/Icon'
import { DropDownWrapper, SelectWrapper } from './styles'
import type { IReducerAction } from './IconLinksReducer'

interface ISelectedItem {
  id: string
  title: string
  icon: JSX.Element
  selectedItemClick: () => void
}

const SUPPORTED_CHANNELS = [
  'Instagram',
  'Facebook',
  'Whatsapp',
  'Twitter',
  'Mastodon',
  'LinkedIn',
  'Youtube',
  'Snapchat',
  'Tiktok',
  'Threads',
  'Bluesky',
  'Patreon',
  'Sms',
  'Twitch',
  'Spotify',
  'AppleMusic',
  'Bandcamp',
  'Discord',
  'Dribbble',
  'Github',
  'Paypal',
  'Soundcloud',
  'Telegram',
  'Pinterest',
  'Mail',
  'Behance',
  'Substack',
  'Medium',
  'Clubhouse',
]

export function Header({
  linksLength,
  dispatch,
}: {
  linksLength: number
  dispatch: React.Dispatch<IReducerAction>
}): JSX.Element {
  const getListItems = (): ISelectedItem[] =>
    SUPPORTED_CHANNELS.map((channel, index) => {
      let IconName = Icons[channel] || Icons.Placeholder
      const newIcon = {
        icon: channel,
        url: '',
        order: linksLength === 0 ? 0 : linksLength,
      }
      let title = channel === 'Mail' ? 'Email' : channel

      if (channel === 'Twitter') {
        title = 'Twitter / X'
        IconName = Icons.X
      }

      return {
        id: index.toString(),
        title,
        icon: <IconName color="gray" />,
        selectedItemClick: (): void => {
          dispatch({ type: 'ADD', newIcon })
        },
      }
    })

  return (
    <DropDownWrapper>
      <Text type="label">Social Links</Text>
      <SelectWrapper>
        <Select
          onSelectClick={(selectedItem: ISelectedItem): void =>
            selectedItem.selectedItemClick()
          }
          label="Add Social Link"
          items={getListItems()}
          size="small"
          hideSearch
          fullWidth
        />
      </SelectWrapper>
    </DropDownWrapper>
  )
}
