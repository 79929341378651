import React, { type Dispatch, type SetStateAction } from 'react'
import Text from '@bufferapp/ui/Text'
import Input from '@bufferapp/ui/Input'
import { OpenNew, Info } from '@bufferapp/ui/Icon'
import Button from '@bufferapp/ui/Button'
import Tooltip from '@bufferapp/ui/Tooltip'
import { getAbsoluteUrl } from '@buffer-mono/sp-display'
import useModal from '~/shared/hooks/useModal'
import { FieldWrapper } from '../styles'
import {
  Wrapper,
  FakePrimaryButton,
  Bottom,
  TransparentButton,
  LabelWrapper,
} from './styles'

interface IInputWithLabelProps {
  label: string
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
  openInNewTab?: boolean
  saveOnBlur?: () => void
  placeholder?: string
  hasError?: boolean
  errorMsg?: string
}

export function InputWithLabel({
  label,
  inputValue,
  setInputValue,
  openInNewTab,
  saveOnBlur,
  placeholder,
  hasError,
  errorMsg,
}: IInputWithLabelProps) {
  const { openModal, setOpenModal, Modal } = useModal()

  return (
    <>
      <FieldWrapper>
        <Text htmlFor={label + inputValue} type="help">
          <LabelWrapper>
            {label}
            {label === 'Alt Text' && (
              <Tooltip
                label="This text allows screen readers to describe your images to visually impaired users"
                position="right"
                verticalAlign="top"
              >
                <Info />
              </Tooltip>
            )}
          </LabelWrapper>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.target.value)
            }}
            name={label}
            value={inputValue}
            id={label + inputValue}
            onBlur={saveOnBlur ? () => saveOnBlur() : null}
            type="text"
            placeholder={placeholder}
            hasError={hasError}
            help={hasError ? errorMsg : ''}
          />
          {openInNewTab && (
            <span>
              <TransparentButton
                onClick={() => setOpenModal(true)}
                aria-label="Open link in new tab"
              >
                <OpenNew />
              </TransparentButton>
            </span>
          )}
        </Text>
      </FieldWrapper>
      {openModal && (
        <Modal>
          <Wrapper>
            <Text type="h3">This link will open in a new tab</Text>
            <Bottom>
              <div style={{ width: '50%', marginRight: '16px' }}>
                <Button
                  type="secondary"
                  label="Cancel"
                  fullWidth
                  onClick={() => setOpenModal(false)}
                />
              </div>
              <FakePrimaryButton
                href={getAbsoluteUrl(inputValue)}
                target="_blank"
                onClick={() => setOpenModal(false)}
              >
                Continue
              </FakePrimaryButton>
            </Bottom>
          </Wrapper>
        </Modal>
      )}
    </>
  )
}
