import React from 'react'

/**
 *
 */
export default function useToggle(initialValue = false): {
  value: boolean
  onToggle: () => void
} {
  const [value, setValue] = React.useState<boolean>(initialValue)

  const onToggle = React.useCallback((): void => {
    setValue((currentValue) => !currentValue)
  }, [])

  return {
    value,
    onToggle,
  }
}
