import React, { useState, useEffect, useReducer } from 'react'
import SocialIconsIcon from '@bufferapp/ui/Icon/Icons/SocialIcons'
import type { IconLinksBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import { cleanTypename } from '~/shared/utils/cleanTypename'
import { EmptyList } from '~/modules/pages/components/EmptyList'
import { BLOCK_CATEGORIES } from '../../index'
import IconLinksReducer from './IconLinksReducer'
import { Header } from './Header'
import { IconLinksList } from './IconLinksList'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ESSENTIALS,
  type: 'ICON_LINKS',
  databaseType: 'StartPageBlockIconLinks',
  icon: {
    Component: SocialIconsIcon,
    color: '#01c8cf',
  },
}

export const addButtonSettings = {
  label: 'Social Links',
  description: 'Ideal for multiple social channels',
}

export const editButtonSettings = {
  label: 'Social Links',
  contentField: null,
}

/**
 *
 */
export function BlockForm({ block }: { block: IconLinksBlock }) {
  const [iconLinksBlock, setIconLinksBlock] = useState(() => block)
  const [state, dispatch] = useReducer(IconLinksReducer, {
    links: cleanTypename(iconLinksBlock).links || [],
    needsToBeSaved: false,
  })

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: iconLinksBlock,
  })

  useEffect(() => {
    if (state.links !== iconLinksBlock.links && state.links.length > 0) {
      setChanges({ links: state.links })
    } else {
      setChanges(null)
    }
  }, [state.links])

  useEffect(() => {
    if (state.needsToBeSaved) {
      saveBlock()
    }
  }, [changes])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !iconLinksBlock?.id) {
      setIconLinksBlock(response.addBlock.block)
    }
  }, [response])

  return (
    <>
      <Header linksLength={state.links.length} dispatch={dispatch} />
      {state.links.length === 0 ? (
        <EmptyList>Add an icon link using the menu above</EmptyList>
      ) : (
        <IconLinksList
          links={state.links}
          saveOnBlur={saveOnBlur}
          dispatch={dispatch}
        />
      )}
    </>
  )
}
