import React, { useState, useEffect } from 'react'
import SubheadingIcon from '@bufferapp/ui/Icon/Icons/Subheading'

import { RichTextEditor } from '~/shared/components/FormElements/RichTextEditor'

import type { TextBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import formatTextForEditor from '~/shared/utils/formatTextForEditor'
import { BLOCK_CATEGORIES } from '../../index'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ESSENTIALS,
  type: 'TEXT',
  databaseType: 'StartPageBlockText',
  icon: {
    Component: SubheadingIcon,
    color: '#9d2fff',
  },
}

export const addButtonSettings = {
  label: 'Text',
  description: 'A line or two to spill the beans',
}

export const editButtonSettings = {
  label: 'Text',
  contentField: 'htmlJson',
}

/**
 *
 */
export function BlockForm({ block }: { block: TextBlock }) {
  const [textBlock, setTextBlock] = useState(() => block)
  const [textAreaValue, setTextAreaValue] = useState(() => block?.text || '')
  const [editorSerializedHtml, setSerializedHtml] = useState(
    () => block?.htmlText || '',
  )
  const [textEditorValue, setTextEditorValue] = useState(
    block.htmlJson || formatTextForEditor(block),
  )

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: textBlock,
  })

  useEffect(() => {
    if (textEditorValue !== block.htmlJson) {
      setChanges({
        htmlText: editorSerializedHtml,
        htmlJson: textEditorValue,
      })
    } else setChanges(null)
  }, [textEditorValue, editorSerializedHtml, textAreaValue])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !textBlock?.id) {
      setTextBlock(response.addBlock.block)
    }
  }, [response])

  return (
    <RichTextEditor
      initialValue={textEditorValue}
      setTextAreaValue={setTextAreaValue}
      setTextEditorValue={setTextEditorValue}
      setSerializedHtml={setSerializedHtml}
      saveOnBlur={saveOnBlur}
      savedText={textAreaValue}
    />
  )
}
