import React, { useState, useRef } from 'react'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'
import { Camera, Warning } from '@bufferapp/ui/Icon'
import { getFallbackImage } from '@buffer-mono/sp-display'
import {
  Wrapper,
  SmallPreviewWrapper,
  ButtonWrapper,
  StyledImage,
  StyledCameraIcon,
  ErrorWrapper,
} from './styles'

export function UploadMedia({
  smallPreview,
  setSelectedFile,
  loading,
  errorUploading,
  id,
}: {
  smallPreview: string
  setSelectedFile: (event: any) => void
  loading: boolean
  errorUploading: boolean
  id?: string
}) {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [fileUrl, setFileUrl] = useState(smallPreview)
  const [error, setError] = useState(false)

  const handleClick = () => {
    hiddenFileInput.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setError(false)
      setFileUrl(URL.createObjectURL(event.target.files[0]))
      setSelectedFile(event.target.files[0])
    }
  }

  return (
    <>
      <Wrapper id={id}>
        <SmallPreviewWrapper>
          {fileUrl ? (
            <StyledImage
              src={fileUrl}
              width="40"
              height="40"
              onError={(
                event: React.SyntheticEvent<HTMLImageElement, Event>,
              ) => {
                getFallbackImage(event)
                setError(true)
              }}
            />
          ) : (
            <StyledCameraIcon />
          )}
        </SmallPreviewWrapper>
        <ButtonWrapper>
          <Button
            type="secondary"
            fullWidth
            icon={<Camera />}
            label={loading ? 'Uploading...' : 'Upload Image (PNG, JPG or GIF)'}
            onClick={handleClick}
            disabled={loading}
          />
        </ButtonWrapper>
        <input
          type="file"
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          accept=".jpg, .jpeg, .gif, .png, image/jpg, image/jpeg, image/gif, image/png"
        />
      </Wrapper>
      {error && (
        <ErrorWrapper role="alert">
          <Text htmlFor="" type="help" hasError>
            <Warning /> Whoops, something went wrong
          </Text>
        </ErrorWrapper>
      )}
      {errorUploading && (
        <ErrorWrapper role="alert">
          <Text htmlFor="" type="help" hasError>
            <Warning /> We can't upload this file because it's too large. Could
            you try a smaller file?
          </Text>
        </ErrorWrapper>
      )}
    </>
  )
}
