import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useStartPageContext } from '~/modules/pages/state/context'
import { useAccountContext } from '~/modules/account/state/context'
import type { AnyBlock } from '@buffer-mono/sp-display'
import type { CallbackMethods } from './useTrackingTypes'

import type { NewBlock } from '../../interfaces'
import {
  setAccountMetadata,
  setAtEventMetaData,
  setSPMetadata,
} from './useTrackingUtils'

export default function useTracking(): CallbackMethods {
  const { startPageData } = useStartPageContext()
  const { accountData } = useAccountContext()
  const isImpersonation =
    accountData && 'isImpersonation' in accountData
      ? accountData.isImpersonation
      : false

  const metadataSP = setSPMetadata(startPageData)
  const metadataAccount = setAccountMetadata(
    accountData?.currentOrganization?.id,
  )
  const metadataAtEvent = setAtEventMetaData(accountData)

  const metadata =
    metadataSP && metadataAccount ? { ...metadataAccount, ...metadataSP } : null

  /*
   *  BLOCK ADDED
   */
  const trackBlockAdded = (block: NewBlock): void => {
    if (!metadata) return

    BufferTracker.startPageBlockAdded({
      ...metadata,
      blockType: block.__typename,
    })
  }

  /*
   *  BLOCK DELETED
   */
  const trackBlockDeleted = (block: AnyBlock): void => {
    if (isImpersonation || !metadata || !block) return

    BufferTracker.startPageBlockDeleted({
      ...metadata,
      blockType: block.__typename,
    })
  }

  /*
   *  BLOCK MOVED
   */
  const trackBlockMoved = (block: AnyBlock): void => {
    if (isImpersonation || !metadata || !block) return

    BufferTracker.startPageBlockMoved({
      ...metadata,
      blockType: block.__typename,
    })
  }

  /*
   *  BACKGROUND EDITED
   */
  const trackBackgroundEdited = (
    backgroundType: 'uploaded' | 'unsplash',
  ): void => {
    if (isImpersonation || !metadata || !backgroundType) return

    BufferTracker.startPageBackgroundEdited({
      ...metadata,
      backgroundType,
    })
  }

  /*
   *  AVATAR EDITED
   */
  const trackAvatarEdited = (backgroundType: 'uploaded' | 'unsplash'): void => {
    if (isImpersonation || !metadata || !backgroundType) return

    BufferTracker.startPageAvatarEdited({
      ...metadata,
      backgroundType,
    })
  }

  /*
   *  HEADER EDITED
   */
  const trackHeaderEdited = (headerLayout: string): void => {
    if (isImpersonation || !metadata || !headerLayout) return

    BufferTracker.startPageHeaderEdited({
      ...metadata,
      headerLayout,
    })
  }

  /*
   *  SIGNUP EDITED
   */
  const trackSignup = (): void => {
    if (isImpersonation || !metadata) return

    BufferTracker.productSignup({
      product: metadata.product,
      organizationId: metadata.organizationId,
      clientName: metadata.clientName,
    })
  }

  /*
   *  THEME SELECTED
   */
  const trackThemeSelected = (themeId: string): void => {
    if (isImpersonation || !metadata || !themeId) return

    BufferTracker.startPageThemeSelected({
      ...metadata,
      themeId,
    })
  }

  /*
   *  THEME EDITED
   */
  const trackThemeEdited = (themeId: string): void => {
    if (isImpersonation || !metadata || !themeId) return

    BufferTracker.startPageThemeEdited({
      ...metadata,
      themeId,
    })
  }

  /*
   *  URL COPIED
   */
  const trackURLCopied = (url: string): void => {
    if (!metadata || !url) return

    BufferTracker.startPageUrlCopied({
      ...metadata,
      url,
    })
  }

  /*
   *  USERNAME SELECTED
   */
  const trackUsernameSelected = (username: string): void => {
    if (isImpersonation || !metadata || !username) return

    BufferTracker.startPageUsernameSeleted({
      ...metadata,
      username,
    })
  }

  /*
   *  USERNAME UPDATED
   */
  const trackUsernameUpdated = (username: string): void => {
    if (isImpersonation || !metadata || !username) return

    BufferTracker.startPageUsernameUpdated({
      ...metadata,
      username,
    })
  }

  /*
   *  STATISTICS VIEWED
   */
  const trackStatisticsViewed = (): void => {
    if (isImpersonation || !metadata) return

    BufferTracker.startPageStatisticsViewed({
      ...metadata,
    })
  }

  /*
   * BLOCK HIDDEN
   */
  const trackBlockHidden = (block: AnyBlock): void => {
    if (isImpersonation || !metadata || !block) return

    BufferTracker.startPageBlockHidden({
      ...metadata,
      blockType: block.__typename,
    })
  }

  /*
   * BLOCK MADE VISIBLE
   */
  const trackBlockMadeVisible = (block: AnyBlock): void => {
    if (isImpersonation || !metadata || !block) return

    BufferTracker.startPageBlockMadeVisible({
      ...metadata,
      blockType: block.__typename,
    })
  }

  /*
   * GIF SELECTED
   */
  const trackGifSelected = (gifId: string): void => {
    if (isImpersonation || !metadata || !gifId) return

    BufferTracker.startPageGifSelected({
      ...metadata,
      gifId,
    })
  }

  /*
   * LIMIT HIT
   */
  const trackPlanLimitHit = ({
    defaultTemplate,
    createdStartPages,
  }: {
    defaultTemplate?: string
    createdStartPages: number
  }): void => {
    if (isImpersonation || !metadata || !createdStartPages) return

    BufferTracker.startPagePlanLimitHit({
      ...metadata,
      defaultTemplate,
      createdStartPages,
    })
  }

  /*
   * UPGRADED PATH VIEWED
   */
  const trackUpgradePathViewed = (upgradePathName: string): void => {
    if (isImpersonation || !metadataAccount || !upgradePathName) return

    BufferTracker.upgradePathViewed({
      ...metadataAccount,
      ...metadataAtEvent,
      upgradePathName,
    })
  }

  /*
   * CTA VIEWED
   */
  const trackCTAViewed = ({
    cta,
    upgradePathName,
  }: {
    cta: string
    upgradePathName: string
  }): void => {
    if (isImpersonation || !metadataAccount || !upgradePathName || !cta) return

    BufferTracker.ctaViewed({
      ...metadataAccount,
      ...metadataAtEvent,
      upgradePathName,
      cta,
    })
  }

  /*
   * CTA CLICKED
   */
  const trackCTAClicked = ({
    cta,
    upgradePathName,
  }: {
    cta: string
    upgradePathName: string
  }): void => {
    if (isImpersonation || !metadataAccount || !upgradePathName || !cta) return

    BufferTracker.ctaClicked({
      ...metadataAccount,
      ...metadataAtEvent,
      upgradePathName,
      cta,
    })
  }

  return {
    trackBlockAdded,
    trackBlockDeleted,
    trackBlockMoved,
    trackBackgroundEdited,
    trackAvatarEdited,
    trackHeaderEdited,
    trackSignup,
    trackThemeSelected,
    trackThemeEdited,
    trackURLCopied,
    trackUsernameSelected,
    trackUsernameUpdated,
    trackStatisticsViewed,
    trackBlockHidden,
    trackBlockMadeVisible,
    trackGifSelected,
    trackPlanLimitHit,
    trackUpgradePathViewed,
    trackCTAViewed,
    trackCTAClicked,
  }
}
