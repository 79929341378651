import React from 'react'
import styled, { css } from 'styled-components'
import Button from '@bufferapp/ui/Button'

const AlignWrapper = styled.div`
  display: flex;

  & > * {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`

interface IButtonWrapperProps {
  active: boolean
}

export const ButtonWrapper = styled.div<IButtonWrapperProps>`
  ${(props) =>
    props.active &&
    css`
      div[type='secondary'] {
        background-color: #acb9fd;
      }
    `}
`

interface IBoldProps {
  bold: boolean
  setBold: React.Dispatch<React.SetStateAction<boolean>>
}

export function Bold({ bold, setBold }: IBoldProps) {
  return (
    <ButtonWrapper active={bold}>
      <Button
        type="secondary"
        icon={<p>B</p>}
        hasIconOnly
        onClick={() => {
          setBold(!bold)
        }}
        label="Bold"
      />
    </ButtonWrapper>
  )
}

interface IAlignProps {
  centerAlign: boolean
  setAlignmentDirection: React.Dispatch<React.SetStateAction<boolean>>
}

export function Align({ centerAlign, setAlignmentDirection }: IAlignProps) {
  return (
    <AlignWrapper>
      <ButtonWrapper active={!centerAlign}>
        <Button
          type="secondary"
          icon={<p>L</p>} // TO DO: add icon
          hasIconOnly
          onClick={() => {
            setAlignmentDirection(false)
          }}
          label="Left Align"
          id="left"
        />
      </ButtonWrapper>
      <ButtonWrapper active={centerAlign}>
        <Button
          type="secondary"
          icon={<p>C</p>} // TO DO: add icon
          hasIconOnly
          onClick={() => {
            setAlignmentDirection(true)
          }}
          label="Center Align"
          id="center"
        />
      </ButtonWrapper>
    </AlignWrapper>
  )
}
