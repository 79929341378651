import type { IStartPageTheme } from '@buffer-mono/sp-display'

interface IReducerState {
  savedTheme: IStartPageTheme | undefined
  newTheme: IStartPageTheme | any
  changesMade: boolean
  selectedThemeId: string
}

export interface IButtonBlockStyles {
  blockType: string
  backgroundColor: string
  textColor: string
}

export type IReducerAction =
  | {
      type: 'ADD_THEME'
      newTheme: IStartPageTheme | undefined
    }
  | {
      type: 'EDIT_BUTTON_COLORS'
      blockStyles: IButtonBlockStyles
    }
  | {
      type: 'EDIT_FONTS'
      fonts: {
        header: string
        body: string
      }
    }

export function isEmpty(obj: any) {
  return Object.keys(obj).length === 0
}

const getCopy = (theme: IStartPageTheme) => ({
  id: theme.id,
  globalStyles: { ...theme.globalStyles },
  blockStyles: theme.blockStyles.map((blockStyle: any) => ({
    ...blockStyle,
  })),
})

const AppearanceReducer = (state: IReducerState, action: IReducerAction) => {
  switch (action.type) {
    case 'ADD_THEME': {
      return {
        ...state,
        newTheme: action.newTheme ? getCopy(action.newTheme) : {},
        changesMade: true,
        selectedThemeId: action.newTheme?.id || '',
      }
    }
    case 'EDIT_BUTTON_COLORS': {
      const copy = getCopy(
        isEmpty(state.newTheme) ? state.savedTheme : state.newTheme,
      )

      const newStyles = copy.blockStyles.map((block: any) => {
        if (block.blockType === 'BUTTON_LINK') {
          return {
            ...block,
            ...action.blockStyles,
          }
        }
        return {
          ...block,
        }
      })

      return {
        ...state,
        newTheme: {
          ...copy,
          blockStyles: newStyles,
        },
        changesMade: true,
      }
    }
    case 'EDIT_FONTS': {
      const { header, body } = action.fonts
      const copy = getCopy(
        isEmpty(state.newTheme) ? state.savedTheme : state.newTheme,
      )

      const newStyles = copy.blockStyles.map((block: any) => {
        if (!block) return null

        const { fontFamily, secondaryFontFamily } = block

        let blockCopy = { ...block }
        const fontFamilyFont = block.blockType === 'HEADER' ? header : body

        if (fontFamily) {
          blockCopy = { ...blockCopy, fontFamily: fontFamilyFont }
        }
        if (secondaryFontFamily) {
          blockCopy = { ...blockCopy, secondaryFontFamily: body }
        }

        return { ...blockCopy }
      })

      return {
        ...state,
        newTheme: {
          ...copy,
          blockStyles: newStyles,
        },
        changesMade: true,
      }
    }
    default:
      throw new Error("That type doesn't exist")
  }
}

export default AppearanceReducer
