import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${(props) => {
    const { color = '#000000' } = props

    return css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 10%;
        background-color: ${color};
        border-radius: 8px;
      }

      svg {
        color: ${color};
        fill: ${color};
        width: 33%;
        height: 33%;
        position: relative;
      }
    `
  }}
`

/**
 *
 */
function Icon({
  color,
  children,
  ...props
}: {
  color: string
  children: React.ReactNode
  props?: any
}) {
  return (
    <Wrapper color={color} {...props}>
      {children}
    </Wrapper>
  )
}

export default Icon
