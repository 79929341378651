import React, { useState, useEffect } from 'react'
import FeedIcon from '@bufferapp/ui/Icon/Icons/Feed'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import AddIcon from '@bufferapp/ui/Icon/Icons/Add'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'

import { InputWithLabel, FieldSet } from '~/shared/components'
import { BlockForm as FeedPostForm } from '~/modules/pages/blocks/FeedPost'
import { useStartPageContext } from '~/modules/pages/state/context'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import type { FeedBlock, FeedPostBlock } from '@buffer-mono/sp-display'
import { BLOCK_CATEGORIES } from '../../index'

import {
  Wrapper,
  HelpTextWrapper,
  ContentWrapper,
  FeedsWrapper,
  Link,
  TextWrapper,
  List,
  Item,
} from './styles'

import { env } from '~/env'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ADVANCED,
  type: 'FEED',
  databaseType: 'StartPageBlockFeed',
  icon: {
    Component: FeedIcon,
    color: '#01c8cf',
  },
  unique: true,
  isNew: false,
}

/**
 *
 */
export const addButtonSettings = {
  label: 'Updates',
  description: 'Schedule posts to your Start Page',
}

/**
 *
 */
export const editButtonSettings = {
  label: 'Updates',
  contentField: null,
}

const BACKGROUND_IMAGE_URL =
  'https://buffer-start-page.s3.amazonaws.com/feed-block.jpg'

const PUBLISH_URL =
  env.VITE_BACKEND_MODE === 'development'
    ? 'https://publish.dev.buffer.com'
    : 'https://publish.buffer.com'

/**
 *
 */
export function BlockForm({ block }: { block: FeedBlock }): JSX.Element {
  const { startPageData } = useStartPageContext()
  const [feedBlock, setFeedBlock] = useState(() => block)
  const [titleValue, setTitleValue] = useState(() => block?.title || '')

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: feedBlock,
  })

  const CALENDAR_URL = `${PUBLISH_URL}/calendar`

  const QUEUE_URL = startPageData
    ? `${PUBLISH_URL}/profile/${startPageData.id}/tab/queue`
    : ''

  const goToQueue = React.useCallback(() => {
    window.location.assign(QUEUE_URL)
  }, [QUEUE_URL])

  const { children } = block

  useEffect(() => {
    if (titleValue !== block.title) {
      setChanges({
        title: titleValue,
      })
    } else setChanges(null)
  }, [titleValue, block.title, setChanges])

  const saveOnBlur = (): void => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !feedBlock?.id) {
      setFeedBlock(response.addBlock.block)
    }
  }, [response, feedBlock])

  const { hasUnPublishedChanges = false } = startPageData || {}

  return (
    <>
      <FieldSet>
        <InputWithLabel
          label="Header"
          inputValue={titleValue}
          setInputValue={setTitleValue}
          saveOnBlur={saveOnBlur}
        />
      </FieldSet>
      <Wrapper>
        <Text type="help">Scheduled Posts</Text>
        <HelpTextWrapper highlighted={hasUnPublishedChanges}>
          <WarningIcon size="medium" />
          {hasUnPublishedChanges ? (
            <p>
              Make sure to publish your Start Page before you create a post.
            </p>
          ) : (
            <p>
              You can schedule and manage your Start Page posts directly from
              the
              <Link href={QUEUE_URL}>channel queue.</Link>
            </p>
          )}
        </HelpTextWrapper>
        {children && children.length > 0 ? (
          <>
            <Button
              type="secondary"
              onClick={goToQueue}
              label="Create a post"
              icon={<AddIcon />}
              fullWidth
            />
            <Text type="help">Published Posts</Text>
            <FeedsWrapper data-testid="feed">
              {children.map((childBlock) => (
                <FeedPostForm
                  block={childBlock as FeedPostBlock}
                  key={childBlock.id}
                />
              ))}
            </FeedsWrapper>
          </>
        ) : (
          <ContentWrapper data-testid="schedule">
            <img
              src={BACKGROUND_IMAGE_URL}
              alt="Schedule and publish posts on your Start Page"
            />
            <TextWrapper>
              <Text type="h3">
                Schedule and publish posts on your Start Page
              </Text>
              <Text type="p">
                Posts are a great way to share updates with your audience. To
                create a post:
              </Text>
              <List>
                <Item>
                  <Text type="p">
                    Head to
                    <Link href={CALENDAR_URL}>calendar</Link>
                    or
                    <Link href={QUEUE_URL}>queue</Link>, select your Start page
                    and create a post.
                  </Text>
                </Item>
                <Item>
                  <Text type="p">
                    Add your post caption, image, and link (optional) then hit
                    publish.
                  </Text>
                </Item>
              </List>
              <Text type="p">
                Once you have published the post, it will be live on your page.
                Scheduled posts will become visible whenever you scheduled them
                for.
              </Text>
            </TextWrapper>

            {!hasUnPublishedChanges && (
              <Button
                type="primary"
                onClick={goToQueue}
                label="Create Post"
                fullWidth
              />
            )}
          </ContentWrapper>
        )}
      </Wrapper>
    </>
  )
}
