import React, { useEffect, useState } from 'react'
import Input from '@bufferapp/ui/Input'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'
import { Warning } from '@bufferapp/ui/Icon'
import { BASE_URL } from '~/shared/constants'
import type { ErrorState } from '~/app/state/hooks/usePublish'
import { InputWrapper, Error } from './styles'

interface IErrorContainerProps {
  hasError: boolean
  error: ErrorState
  displayError: boolean
}

interface IDomainInputProps {
  id?: string
  domain: string | undefined
  onContinue: (newDomain: string) => void
  buttonLabel: string
  loading: boolean
  error: ErrorState
}

const isValidDomain = (newDomain: string) => {
  const hasValidCharacters = /^[a-z0-9-]*$/.test(newDomain)
  const hasValidLength = newDomain.length >= 3
  const startsWithHyphen = /-/.test(newDomain.charAt(0))

  return hasValidCharacters && hasValidLength && !startsWithHyphen
}

function ErrorContainer({
  hasError,
  error,
  displayError,
}: IErrorContainerProps) {
  if (hasError) {
    return (
      <Error>
        <Warning />
        Please add at least 3 valid characters (lower case letters, numbers or
        hyphens).
      </Error>
    )
  }
  if (error && displayError) {
    return (
      <Error>
        <Warning />
        {error.message}
      </Error>
    )
  }

  return null
}

export function DomainInput({
  id,
  domain,
  onContinue,
  buttonLabel,
  loading,
  error,
}: IDomainInputProps) {
  const [newDomain, setNewDomain] = useState(() => domain)
  const [hasError, setHasError] = useState(false)
  const [displayError, setDisplayError] = useState(!!error)

  const domainChanged = domain !== newDomain

  const onDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setNewDomain(value.trim().toLowerCase())
    if (error) setDisplayError(false)
    if (hasError) setHasError(false)
  }

  const onUpdate = () => {
    if (!newDomain) return

    if (isValidDomain(newDomain)) {
      onContinue(newDomain)
    } else {
      setHasError(true)
    }
  }

  useEffect(() => {
    if (error) {
      setDisplayError(true)
    }
  }, [error])

  useEffect(() => {
    setNewDomain(domain)
  }, [domain])

  return (
    <div id={id}>
      <InputWrapper>
        <Input
          type="input"
          onChange={onDomainChange}
          name="domain"
          placeholder="Your Business Name"
          value={newDomain}
          hasError={hasError || displayError}
        />
        <Text type="p">{BASE_URL}</Text>
      </InputWrapper>
      <ErrorContainer
        hasError={hasError}
        error={error}
        displayError={displayError}
      />
      <Button
        type="primary"
        disabled={!newDomain || !domainChanged || loading}
        onClick={onUpdate}
        label={loading ? 'Publishing' : buttonLabel}
        fullWidth
      />
    </div>
  )
}
