import styled from 'styled-components'
import {
  gray,
  white,
  grayDarker,
  grayDark,
  grayLight,
  grayLighter,
} from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  background-color: ${white};
  border: 1px solid ${gray};
  border-radius: 4px;
  margin-bottom: 8px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: solid 1px ${grayLight};

  p {
    color: ${grayDark};
  }
`

export const Bottom = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

export const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(224, 224, 224);
  display: flex;
  align-items: center;
  flex: none;
`

export const StyledImage = styled.img`
  border-radius: 8px;
  width: 80px;
  height: 80px;
  object-fit: cover;
`

export const ContentWrapper = styled.div`
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const LinkBackground = styled.div`
  width: 24px;
  height: 24px;
  background: ${grayLighter};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  flex: none;

  svg {
    fill: ${grayDarker};
    width: 12px;
    height: 12px;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 295px;

  p {
    color: ${grayDark};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

/* https://css-tricks.com/line-clampin/#aa-the-standardized-way */
export const Module = styled.div`
  margin: 0 0 1em 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
