import {
  type AutoformatRule,
  createPlugins,
  createTEditor,
  type Decorate,
  type DecorateEntry,
  type DOMHandler,
  type EDescendant,
  type EElement,
  type EElementEntry,
  type EElementOrText,
  type ELEMENT_PARAGRAPH,
  type EMarks,
  type ENode,
  type ENodeEntry,
  type EText,
  type ETextEntry,
  getTEditor,
  type InjectComponent,
  type InjectProps,
  type KeyboardHandler,
  type OnChange,
  type OverrideByKey,
  type PlateEditor,
  type PlatePlugin,
  type PlatePluginComponent,
  type PlatePluginInsertData,
  type PlatePluginProps,
  type PlateProps,
  type PluginOptions,
  type SerializeHtml,
  type TElement,
  type TNodeEntry,
  type TReactEditor,
  type TText,
  useEditorRef,
  useEditorState,
  usePlateEditorRef,
  type WithOverride,
} from '@udecode/plate'
import type { CSSProperties } from 'styled-components'

/**
 * Text
 */

export type EmptyText = {
  text: ''
}

export type PlainText = {
  text: string
}

export interface RichText extends TText {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  code?: boolean
  kbd?: boolean
  subscript?: boolean
  backgroundColor?: CSSProperties['backgroundColor']
  fontFamily?: CSSProperties['fontFamily']
  color?: CSSProperties['color']
  fontSize?: CSSProperties['fontSize']
  fontWeight?: CSSProperties['fontWeight']
}

/**
 * Inline Elements
 */

export type MyInlineDescendant = RichText
export type MyInlineChildren = MyInlineDescendant[]

/**
 * Blocks
 */

export type MyBlockElement = TElement
export interface MyParagraphElement extends MyBlockElement {
  type: typeof ELEMENT_PARAGRAPH
  children: MyInlineChildren
}

export type MyNestableBlock = MyParagraphElement

export type MyRootBlock = MyParagraphElement

export type RichTextValue = MyRootBlock[]

/**
 * Editor types
 */

export type MyEditor = PlateEditor<RichTextValue> & { isDragging?: boolean }
export type MyReactEditor = TReactEditor<RichTextValue>
export type MyNode = ENode<RichTextValue>
export type MyNodeEntry = ENodeEntry<RichTextValue>
export type MyElement = EElement<RichTextValue>
export type MyElementEntry = EElementEntry<RichTextValue>
export type MyText = EText<RichTextValue>
export type MyTextEntry = ETextEntry<RichTextValue>
export type MyElementOrText = EElementOrText<RichTextValue>
export type MyDescendant = EDescendant<RichTextValue>
export type MyMarks = EMarks<RichTextValue>
export type MyMark = keyof MyMarks

export type MyBlock = Exclude<MyElement, null>
export type MyBlockEntry = TNodeEntry<MyBlock>

/**
 * Plate types
 */

export type MyDecorate<P = PluginOptions> = Decorate<P, RichTextValue, MyEditor>
export type MyDecorateEntry = DecorateEntry<RichTextValue>
export type MyDOMHandler<P = PluginOptions> = DOMHandler<
  P,
  RichTextValue,
  MyEditor
>
export type MyInjectComponent = InjectComponent<RichTextValue>
export type MyInjectProps = InjectProps<RichTextValue>
export type MyKeyboardHandler<P = PluginOptions> = KeyboardHandler<
  P,
  RichTextValue,
  MyEditor
>
export type MyOnChange<P = PluginOptions> = OnChange<P, RichTextValue, MyEditor>
export type MyOverrideByKey = OverrideByKey<RichTextValue, MyEditor>
export type MyPlatePlugin<P = PluginOptions> = PlatePlugin<
  P,
  RichTextValue,
  MyEditor
>
export type MyPlatePluginInsertData = PlatePluginInsertData<RichTextValue>
export type MyPlatePluginProps = PlatePluginProps<RichTextValue>
export type MyPlateProps = PlateProps<RichTextValue, MyEditor>
export type MySerializeHtml = SerializeHtml<RichTextValue>
export type MyWithOverride<P = PluginOptions> = WithOverride<
  P,
  RichTextValue,
  MyEditor
>

/**
 * Plate store, Slate context
 */

export const getMyEditor = (editor: MyEditor) =>
  getTEditor<RichTextValue, MyEditor>(editor)
export const useMyEditorRef = () => useEditorRef<RichTextValue, MyEditor>()
export const useMyEditorState = () => useEditorState<RichTextValue, MyEditor>()
export const useMyPlateEditorRef = (id?: string) =>
  usePlateEditorRef<RichTextValue, MyEditor>(id)

/**
 * Utils
 */
export const createMyEditor = () => createTEditor() as MyEditor
export const createMyPlugins = (
  plugins: MyPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>
    overrideByKey?: MyOverrideByKey
  },
) => createPlugins<RichTextValue, MyEditor>(plugins, options)

export type MyAutoformatRule = AutoformatRule<RichTextValue, MyEditor>
