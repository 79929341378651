import styled, { css } from 'styled-components'
import { grayLight, blueLighter, grayDarker } from '@bufferapp/ui/style/colors'

export const BlockWrapper = styled.div`
  background: white;
  margin: 0px 16px 16px;
  padding: 16px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;

  & > * {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

export const EditorWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  background-color: #ffffff;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 8px;
  resize: none;
  color: #3d3d3d;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  height: auto;

  > div:first-child {
    padding: 8px;
    min-height: 80px;
  }

  > div:first-child:focus {
    outline: none;
    box-shadow: 0 0 0 4px ${blueLighter};
    border-radius: 4px;
  }
`

export const toolbarStyle = css`
  margin: 0;
  border-bottom: 0;
  border-top: 1px solid ${grayLight};
  padding: 4px 5px;

  span {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }

  span[class*='active'],
  span:hover {
    border-radius: 4px;
    color: ${grayDarker}!important;
    background-color: #dadada;
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 22px;
  background-color: ${grayLight};
  margin: 0 10px 0 6px;
`
