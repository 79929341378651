import React, { useState, useEffect, useReducer } from 'react'
import { instagram as instagramColor } from '@bufferapp/ui/style/colors'
import InstagramGrid from '@bufferapp/ui/Icon/Icons/InstagramGrid'
import type { ImageLinksGridBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import { cleanTypename } from '~/shared/utils/cleanTypename'
import { EmptyList } from '~/modules/pages/components/EmptyList'
import { BLOCK_CATEGORIES } from '../../index'
import ImageLinksGridReducer from './ImageLinksGridReducer'
import { Header } from './Header'
import { ImageLinksGridList } from './ImageLinksGridList'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ADVANCED,
  type: 'IMAGE_LINKS_GRID',
  databaseType: 'StartPageBlockImageLinksGrid',
  icon: {
    Component: InstagramGrid,
    color: instagramColor,
  },
  comingSoon: false,
}

export const addButtonSettings = {
  label: 'Image Links Grid',
  description: 'Display your images in a grid with links',
}

/**
 *
 */
export const editButtonSettings = {
  label: 'Image Links Grid',
  contentField: null,
}

/**
 *
 */
export function BlockForm({ block }: { block: ImageLinksGridBlock }) {
  const [imageLinksGridBlock, setImageLinksGridBlock] = useState(() => block)
  const [state, dispatch] = useReducer(ImageLinksGridReducer, {
    links: cleanTypename(imageLinksGridBlock).links || [],
    needsToBeSaved: false,
  })

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: imageLinksGridBlock,
  })

  useEffect(() => {
    setChanges({ links: state.links })
  }, [state.links])

  useEffect(() => {
    if (state.needsToBeSaved) {
      saveBlock()
    }
  }, [changes])

  useEffect(() => {
    if (response) {
      const mutationName = response?.addBlock ? 'addBlock' : 'editBlock'
      setImageLinksGridBlock(response[mutationName].block)
    }
  }, [response])

  return (
    <>
      <Header linksLength={state.links.length} dispatch={dispatch} />
      {state.links.length === 0 ? (
        <EmptyList>Add an image to your grid using the button above</EmptyList>
      ) : (
        <ImageLinksGridList links={state.links} dispatch={dispatch} />
      )}
    </>
  )
}
