import type { SocialLink } from '@buffer-mono/sp-display'

interface IReducerState {
  links: SocialLink[] | []
  needsToBeSaved: boolean
}

interface IReorderInfo {
  startIndex: number
  endIndex: number
}

export type IReducerAction =
  | { type: 'ADD'; newIcon: SocialLink }
  | { type: 'EDIT'; newLinkValue: SocialLink }
  | { type: 'REORDER'; reorderInfo: IReorderInfo }
  | { type: 'DELETE'; deleteLinkOrder: number }

const IconLinksReducer = (state: IReducerState, action: IReducerAction) => {
  switch (action.type) {
    case 'ADD': {
      return {
        ...state,
        links: [...state.links, action.newIcon],
        needsToBeSaved: false,
      }
    }
    case 'EDIT': {
      const updatedArray = state.links.map((item, index) => {
        if (item.order === action.newLinkValue.order) {
          return {
            ...item,
            url: action.newLinkValue.url,
          }
        }
        return {
          ...item,
        }
      })

      return {
        ...state,
        links: updatedArray,
        needsToBeSaved: false,
      }
    }
    case 'REORDER': {
      const newBlockArray = Array.from(state.links)
      const [removed] = newBlockArray.splice(action.reorderInfo.startIndex, 1)
      newBlockArray.splice(action.reorderInfo.endIndex, 0, removed)
      const updatedOrderArray = newBlockArray.map((item, index) => ({
        ...item,
        order: index,
      }))

      return {
        ...state,
        links: updatedOrderArray,
        needsToBeSaved: true,
      }
    }
    case 'DELETE': {
      const newBlockArray = Array.from(state.links)
      newBlockArray.splice(action.deleteLinkOrder, 1)
      const newLinks = newBlockArray.map((item, index) => ({
        icon: item.icon,
        order: index,
        url: item.url,
      }))

      return {
        ...state,
        links: newLinks,
        needsToBeSaved: true,
      }
    }
    default:
      throw new Error("That type doesn't exist")
  }
}

export default IconLinksReducer
