import {
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createHeadingPlugin,
  createParagraphPlugin,
  createAlignPlugin,
  ELEMENT_PARAGRAPH,
} from '@udecode/plate'
import { plateUI } from './plateUI'
import { createMyPlugins } from './types'

export const formattingPlugins = createMyPlugins(
  [
    createParagraphPlugin(),
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),
    createHeadingPlugin(),
    createAlignPlugin({
      inject: {
        props: {
          validTypes: [ELEMENT_PARAGRAPH],
        },
      },
    }),
  ],
  {
    components: plateUI,
  },
)
