import { gql } from '@apollo/client'

export const QUERY_ACCOUNT = gql`
  query account {
    account {
      id
      email
      isImpersonation
      hasVerifiedEmail
      currentOrganization {
        id
        role
        featureFlips
        isOneBufferOrganization
        channels {
          type
          avatar
          id
          name
          service
          serviceId
          canPublishStartPage
        }
        billing {
          canAccessEngagement
          gateway {
            gatewayType
          }
        }
        commonTrackingProperties {
          atEventBillingState
          atEventBillingPlan
          atEventBillingCycle
          atEventBillingGateway
          atEventIsNewBuffer
          atEventChannelQty
        }
      }
    }
  }
`
