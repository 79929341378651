import React from 'react'
import CaretDownIcon from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretUpIcon from '@bufferapp/ui/Icon/Icons/CaretUp'

import useToggle from '~/shared/hooks/useToggle'
import GoToMailchimp from '../../shared/components/GoToMailchimp'

import {
  Wrapper,
  AccordionButton,
  AccordionTitle,
  AccordionHeader,
  Text,
  Description,
} from './styles'

/**
 *
 */

function HaveAccount(): React.JSX.Element {
  const { value: isOpened, onToggle } = useToggle(false)

  return (
    <Wrapper data-testid="have-account">
      <AccordionButton
        onClick={onToggle}
        data-testid={'accordion-button-haveaccount'}
      >
        <AccordionHeader>
          <AccordionTitle>
            <Text>
              <b>Yes,</b> I already have a Mailchimp account
            </Text>
          </AccordionTitle>
        </AccordionHeader>
        {isOpened ? <CaretDownIcon /> : <CaretUpIcon />}
      </AccordionButton>
      {isOpened && (
        <Description data-testid="description-haveaccount">
          <Text>
            <b>Great!</b> You&apos;ll need the HTML from your signup form to be
            able to start collecting emails directly in your StartPage (Go to:
            Audience &gt; Signup forms &gt; Embedded forms)
          </Text>
          <GoToMailchimp url={'https://login.mailchimp.com/'} />
        </Description>
      )}
    </Wrapper>
  )
}

export default HaveAccount
