import React from 'react'
import * as Icons from '@bufferapp/ui/Icon'
import FeedIcon from '@bufferapp/ui/Icon/Icons/Feed'
import Text from '@bufferapp/ui/Text'

import { type FeedPostBlock, formatDate } from '@buffer-mono/sp-display'
import useDeleteBlock from '~/app/state/hooks/useDeleteBlock'
import { useNotificationContext } from '~/app/state/notifications-context'
import useModal from '~/shared/hooks/useModal'
import { DeleteBlockModal } from '~/shared/components'
import { IconButton } from '~/modules/pages/components/IconButton'
import { BLOCK_CATEGORIES } from '../../index'

import {
  Wrapper,
  Top,
  ImageWrapper,
  StyledImage,
  Bottom,
  ContentWrapper,
  LinkBackground,
  LinkWrapper,
  Module,
} from './styles'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ADVANCED,
  type: 'FEED_POST',
  databaseType: 'StartPageBlockFeedPost',
  icon: {
    Component: FeedIcon,
    color: '#01c8cf',
  },
}

// We won't allow users to manually create Feed Post blocks.
export const addButtonSettings = null

// We won't display a link to edit Feed Post blocks on the layout section,
// only inside feeds.
export const editButtonSettings = null

/**
 *
 */
export function BlockForm({ block }: { block: FeedPostBlock }) {
  const { setNotificationOptions } = useNotificationContext()
  const { openModal, setOpenModal, Modal } = useModal()

  const {
    deleteBlock,
    response: deleteResponse,
    mutationError: deleteError,
    loading: deleteLoading,
  } = useDeleteBlock({ blockId: block?.id })

  const TrashIcon = Icons.Trash
  const LinkIcon = Icons.Link

  const { createdAt } = block || {}

  const formattedCreatedAt = formatDate(createdAt)

  const hasNoBlock = !block || (!block.url && !block.text && !block.media)

  React.useEffect(() => {
    if (hasNoBlock) return

    if (deleteError) {
      setNotificationOptions({ message: 'Whoops, something went wrong!' })
    } else if (deleteResponse) {
      setNotificationOptions({
        message: 'The block has been deleted successfully!',
      })
    }
  }, [
    hasNoBlock,
    deleteError,
    deleteResponse,
    setNotificationOptions,
    deleteLoading,
  ])

  if (hasNoBlock) {
    return (
      <Wrapper>
        <Bottom>
          <Text type="p">Whoops! Something went wrong</Text>
        </Bottom>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Top>
        <Text type="p" color="greyDark">
          Posted at {formattedCreatedAt}
        </Text>
        <div>
          <IconButton onClick={() => setOpenModal(true)}>
            <TrashIcon color="gray" />
          </IconButton>
        </div>
      </Top>
      <Bottom>
        <ContentWrapper>
          <Module>
            <Text type="p">{block.text}</Text>
          </Module>
          {block?.url && (
            <LinkWrapper>
              <LinkBackground>
                <LinkIcon />
              </LinkBackground>
              <Text type="p">{block?.url}</Text>
            </LinkWrapper>
          )}
        </ContentWrapper>
        {block?.media?.url && (
          <ImageWrapper>
            <StyledImage src={block?.media?.url} alt={block?.media?.altText} />
          </ImageWrapper>
        )}
      </Bottom>
      {openModal && deleteBlock && (
        <Modal>
          <DeleteBlockModal
            setOpenModal={setOpenModal}
            deleteBlock={deleteBlock}
            onReturnClick={() => setOpenModal(false)}
          />
        </Modal>
      )}
    </Wrapper>
  )
}
