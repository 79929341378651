import { useMutation } from '@apollo/client'
import { MUTATION_EDIT_BLOCK } from '~/app/state/mutations'
import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import { useStartPageContext } from '~/modules/pages/state/context'
import type { StartPage, Blocks, AnyBlock } from '@buffer-mono/sp-display'
import useTracking from '~/shared/hooks/useTracking/useTracking'

export type ChangesState = any | null
export type SetChangesState = React.Dispatch<React.SetStateAction<ChangesState>>

type UseEditBlockProps = {
  blockId: string
  changes: ChangesState
}

type GetUpdatedBlocksParam = {
  blockId: string
  changes: ChangesState
  existingBlocks: Blocks
}

export const getUpdatedBlocks = ({
  blockId,
  changes,
  existingBlocks,
}: GetUpdatedBlocksParam) => {
  const newBlockArray = existingBlocks.map((block) => {
    if (block.id === blockId) return { ...block, ...changes }
    return block
  })

  return newBlockArray
}

export default function ({ blockId, changes }: UseEditBlockProps) {
  const { startPageData, setStartPageData } = useStartPageContext()
  const [saveChangesToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_EDIT_BLOCK)

  const { trackAvatarEdited, trackHeaderEdited, trackBackgroundEdited } =
    useTracking()

  const editBlock = () => {
    if (blockId && changes && startPageData?.blocks) {
      const blockToEdit = startPageData.blocks.find((block) => {
        if (block.id === blockId) return block
      })

      const newBlockArray = getUpdatedBlocks({
        blockId,
        changes,
        existingBlocks: startPageData.blocks,
      })

      // TODO: We can use Apollo's optimistic response
      // mechanism instead of doing this. Now that we have
      // an "update" function in place, we can pass
      // an optimistic response next! :)
      setStartPageData({
        ...startPageData,
        hasUnPublishedChanges: true,
        blocks: newBlockArray,
      })

      // save changes to DB
      saveChangesToDB({
        variables: {
          blockId,
          data: changes,
          organizationId: startPageData?.organizationId,
        },
        update: (cache) => {
          const allStartPageData = cache.readQuery<StartPage>({
            query: QUERY_START_PAGE,
            variables: {
              pageId: startPageData?.id,
              organizationId: startPageData?.organizationId,
            },
          })
          cache.writeQuery({
            query: QUERY_START_PAGE,
            data: {
              startPage: {
                ...allStartPageData?.startPage,
                hasUnPublishedChanges: true,
                blocks: newBlockArray,
              },
            },
          })
        },
      })
        .then(() => {
          if (blockToEdit) {
            trackEvents(blockToEdit as AnyBlock, changes)
          }
        })
        .catch((error) => {
          console.log(error)
          // @todo: trigger an error state & refetch data
        })
    }
    // @todo: trigger an error state here
  }

  const trackEvents = (currentBlockData: AnyBlock, newBlockData: AnyBlock) => {
    if (
      'backgroundMedia' in newBlockData &&
      'backgroundMedia' in currentBlockData &&
      currentBlockData.backgroundMedia?.url !==
        newBlockData.backgroundMedia?.url
    ) {
      trackBackgroundEdited('uploaded') // No Unsplash for MVP
    }

    if (
      'logoMedia' in newBlockData &&
      'logoMedia' in currentBlockData &&
      currentBlockData.logoMedia?.url !== newBlockData.logoMedia?.url
    ) {
      trackAvatarEdited('uploaded') // No Unsplash for MVP
    }

    if (
      'layout' in newBlockData &&
      'layout' in currentBlockData &&
      currentBlockData.layout !== newBlockData.layout
    ) {
      trackHeaderEdited(newBlockData.layout)
    }
  }

  return {
    editBlock,
    response: response ? { ...response } : null,
    mutationError,
    loading,
    changes,
  }
}
