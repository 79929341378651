import React from 'react'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { yellow, yellowDarker } from '@bufferapp/ui/style/colors'

const TipWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  p {
    font-size: 12px;
  }
`

const Tag = styled.span`
  margin-right: 4px;
  padding: 2px 6px;
  background: ${yellow};
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: ${yellowDarker};
  border-radius: 4px;
`

export function Tip({ children }: { children: string }) {
  return (
    <TipWrapper>
      <Tag>Tip</Tag>
      <Text type="p">{children}</Text>
    </TipWrapper>
  )
}
