/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useLazyQuery } from '@apollo/client'

import { QUERY_ACCOUNT } from './queries'

/**
 *
 */
export const AccountContext = React.createContext<
  | {
      accountData: any
      accountError: boolean
      accountLoading: boolean
      refetchAccount: any
      getChannelForStartPage: (pageId: string | undefined) => any
      getUserType: () => 'OB_PAID' | 'OB_FREE' | 'MOBILE' | 'MP'
    }
  | undefined
>(undefined)

/**
 *
 */
function useAccountContext() {
  const context = React.useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccountContext is undefined')
  }
  return context
}

/**
 *
 */
function AccountProvider({ children }: { children: React.ReactNode }) {
  const [
    queryAccount,
    {
      data: accountData,
      error: accountQueryError,
      loading: accountQueryLoading,
      refetch: refetchAccount,
    },
  ] = useLazyQuery(QUERY_ACCOUNT)

  const handleOrganizationChange = () => {
    if (refetchAccount) {
      refetchAccount()
    }
  }

  React.useEffect(() => {
    const { ORGANIZATION_EVENT_KEY } = window?.appshell?.eventKeys || {}
    window.addEventListener(ORGANIZATION_EVENT_KEY, handleOrganizationChange)

    return function cleanup() {
      window.removeEventListener(
        ORGANIZATION_EVENT_KEY,
        handleOrganizationChange,
      )
    }
  }, [window?.appshell, refetchAccount])

  React.useEffect(() => {
    if (accountData) return
    queryAccount()
  }, [])

  React.useEffect(() => {
    if (!accountQueryError) return
    console.error('Account loading error: ', accountQueryError)
  }, [accountQueryError])

  const getUserType = () => {
    const { billing, isOneBufferOrganization } =
      accountData.account.currentOrganization
    const { gateway, canAccessEngagement } = billing

    if (['revenuecat', 'android', 'apple'].includes(gateway?.gatewayType)) {
      return 'MOBILE'
    }

    if (!isOneBufferOrganization) {
      return 'MP'
    }

    return canAccessEngagement ? 'OB_PAID' : 'OB_FREE'
  }

  const getChannelForStartPage = React.useCallback(
    (pageId: string | undefined) => {
      const { account } = accountData
      if (!account || !pageId) return null
      const { currentOrganization } = account
      const { channels } = currentOrganization || {}
      return channels ? channels.find((el: any) => el.id === pageId) : null
    },
    [accountData],
  )

  const value = {
    accountData: accountData?.account,
    accountError: !!accountQueryError,
    accountLoading: accountQueryLoading,
    refetchAccount,
    getChannelForStartPage,
    getUserType,
  }

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}

export { AccountProvider, useAccountContext }
