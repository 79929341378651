import { gql } from '@apollo/client'

export const MUTATION_UNPUBLISH = gql`
  mutation unPublishStartPage($organizationId: String!, $pageId: String!) {
    unPublishStartPage(organizationId: $organizationId, pageId: $pageId) {
      ... on StartPageMutationSuccess {
        startPage {
          id
          domain
          isPublished
          hasUnPublishedChanges
        }
      }
      ... on MutationResponse {
        code
        success
        message
      }
      ... on CommonError {
        code
        message
      }
    }
  }
`
