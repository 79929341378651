import styled from 'styled-components'
import OpenNewIcon from '@bufferapp/ui/Icon/Icons/OpenNew'

export const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 30px;
`
export const StyledOpenNewIcon = styled(OpenNewIcon)`
  margin-left: 5px;
  vertical-align: text-bottom;
`
