import React, { useState, useEffect } from 'react'
import * as Icons from '@bufferapp/ui/Icon'
import type { SocialLink } from '@buffer-mono/sp-display'
import { FieldSet, InputWithLabel } from '~/shared/components'
import { IconButton } from '~/modules/pages/components/IconButton'
import type { IReducerAction } from './IconLinksReducer'
import { Top, Left } from './styles'

interface IIconLinkMiniFormProps {
  link: SocialLink
  dispatch: React.Dispatch<IReducerAction>
  saveOnBlur: () => void
}

export function IconLinkItem({
  link,
  dispatch,
  saveOnBlur,
}: IIconLinkMiniFormProps): JSX.Element {
  const [linkValue, setLinkValue] = useState(() => link.url || '')

  useEffect(() => {
    if (linkValue !== link.url) {
      const newLinkValue = {
        ...link,
        url: linkValue,
      }
      dispatch({ type: 'EDIT', newLinkValue })
    }
  }, [linkValue, dispatch, link])

  const HamburgerIcon = Icons.Hamburger
  let ChannelIcon = Icons[link.icon] || Icons.Placeholder
  const TrashIcon = Icons.Trash

  const getPlaceholder = (icon: string): string => {
    switch (icon) {
      case 'Mail':
        return 'email@domain.com'
      case 'Sms':
        return ''
      case 'Behance':
        return 'https://www.behance.net/'
      case 'Substack':
        return 'https://you.substack.com'
      case 'Whatsapp':
        return 'https://wa.me/'
      case 'Threads':
        return 'https://threads.net/@'
      case 'Medium':
      case 'Clubhouse':
        return `https://${icon.toLowerCase()}.com/@`
      default:
        return `https://www.${icon.toLowerCase()}.com/`
    }
  }

  let label = link.icon === 'Mail' ? 'Email' : link.icon

  if (link.icon === 'Twitter') {
    label = 'Twitter / X'
    ChannelIcon = Icons.X
  }

  return (
    <FieldSet>
      <Top>
        <Left>
          <HamburgerIcon color="gray" />
          <ChannelIcon color="gray" title={link.icon} />
        </Left>
        <div>
          <IconButton
            onClick={(): void =>
              dispatch({ type: 'DELETE', deleteLinkOrder: link.order })
            }
          >
            <TrashIcon color="gray" />
          </IconButton>
        </div>
      </Top>
      <InputWithLabel
        label={label}
        inputValue={linkValue}
        setInputValue={setLinkValue}
        openInNewTab={link.icon !== 'Mail' && link.icon !== 'Sms'}
        saveOnBlur={saveOnBlur}
        placeholder={getPlaceholder(link.icon)}
      />
    </FieldSet>
  )
}
