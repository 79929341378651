import React from 'react'
import { ChevronDown } from '@bufferapp/ui/Icon'

import {
  DropdownWrapper,
  ButtonWrapper,
  ContentWrapper,
  StyledOption,
  IconWrapper,
  StyledList,
} from './styles'

/**
 *
 */
const DropdownContext = React.createContext<{
  isOpened: boolean
  onToggle: () => void
} | null>(null)

/**
 *
 */
function Dropdown({ children, ...props }: { children?: any; props?: any }) {
  const wrapperRef = React.useRef<HTMLInputElement | null>(null)
  const [isOpened, setIsOpened] = React.useState(false)

  const onToggle = React.useCallback(() => {
    if (!wrapperRef.current) return
    setIsOpened((currentValue) => !currentValue)
  }, [])

  const onClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (!wrapperRef.current) return
      const clickedOutside = !wrapperRef.current.contains(event.target as Node)
      if (clickedOutside) onToggle()
    },
    [onToggle],
  )

  const onEscape = React.useCallback(
    (event: KeyboardEvent) => {
      const key = event.key || event.keyCode
      if (key === 'Escape' || key === 27) {
        onToggle()
      }
    },
    [onToggle],
  )

  React.useEffect(() => {
    if (isOpened) {
      document.addEventListener('click', onClickOutside, true)
      document.addEventListener('keydown', onEscape, true)
    } else {
      document.removeEventListener('click', onClickOutside, true)
      document.removeEventListener('keydown', onEscape, true)
    }
  }, [isOpened])

  const value = React.useMemo(
    () => ({
      isOpened,
      onToggle,
    }),
    [isOpened],
  )

  return (
    <DropdownContext.Provider value={value}>
      <DropdownWrapper ref={wrapperRef} {...props} id="domain-dropdown">
        {children}
      </DropdownWrapper>
    </DropdownContext.Provider>
  )
}

/**
 *
 */
const useToggleContext = () => {
  const context = React.useContext(DropdownContext)

  if (!context) {
    throw new Error(
      `Dropdown compound components cannot be rendered outside the Dropdown component`,
    )
  }

  return context
}

/**
 *
 */
function Button({ children }: { children: any }) {
  const { isOpened, onToggle } = useToggleContext()

  const onEnter = React.useCallback(
    (event: React.KeyboardEvent<HTMLImageElement>) => {
      const key = event.key || event.keyCode
      if (key === 'Enter' || key === 13) {
        onToggle()
      }
    },
    [onToggle],
  )

  return (
    <ButtonWrapper
      role="button"
      aria-haspopup
      aria-pressed={isOpened}
      aria-expanded={isOpened}
      tabIndex={0}
      onClick={onToggle}
      onKeyDown={onEnter}
    >
      {children}
      <IconWrapper>
        <ChevronDown />
      </IconWrapper>
    </ButtonWrapper>
  )
}

/**
 *
 */
function Content({ children }: { children: any }) {
  const { isOpened, onToggle } = useToggleContext()

  return isOpened ? (
    <ContentWrapper onClick={onToggle}>{children}</ContentWrapper>
  ) : null
}

/**
 *
 */
function Options({ children, ...props }: { children: any }) {
  return (
    <StyledList role="listbox" {...props}>
      {children}
    </StyledList>
  )
}

/**
 *
 */
function Option({
  selected,
  children,
  ...props
}: {
  selected: boolean
  children: any
}) {
  return (
    <StyledOption
      tabIndex={0}
      role="option"
      aria-selected={selected}
      {...props}
    >
      {children}
    </StyledOption>
  )
}

Dropdown.Button = Button
Dropdown.Content = Content
Dropdown.Options = Options
Dropdown.Option = Option

export default Dropdown
