import React, { useState, useEffect } from 'react'
import HeadingIcon from '@bufferapp/ui/Icon/Icons/Heading'

import { TextAreaWithLabel, FieldSet } from '~/shared/components'
import type { SubHeadingBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import { BLOCK_CATEGORIES } from '../../index'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ESSENTIALS,
  type: 'SUB_HEADING',
  databaseType: 'StartPageBlockSubHeading',
  icon: {
    Component: HeadingIcon,
    color: '#86c321',
  },
}

export const addButtonSettings = {
  label: 'Sub Heading',
  description: 'Break up your page and add structure',
}

export const editButtonSettings = {
  label: 'Sub Heading',
  contentField: 'text',
}

/**
 *
 */
export function BlockForm({ block }: { block: SubHeadingBlock }) {
  const [subheadingBlock, setSubheadingBlock] = useState(() => block)
  const [textAreaValue, setTextAreaValue] = useState(() => block?.text || '')

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: subheadingBlock,
  })

  useEffect(() => {
    if (textAreaValue !== block.text) {
      setChanges({
        text: textAreaValue,
      })
    } else setChanges(null)
  }, [textAreaValue])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !subheadingBlock?.id) {
      setSubheadingBlock(response.addBlock.block)
    }
  }, [response])

  return (
    <FieldSet>
      <TextAreaWithLabel
        label="Text"
        id="textarea-subheading-text"
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        saveOnBlur={saveOnBlur}
      />
    </FieldSet>
  )
}
