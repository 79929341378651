import React from 'react'

import useModal from '~/shared/hooks/useModal'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import { Row, Button, GiphyImage, Overlay, OverlayText } from './styles'
import { GiphyModal } from './components/GiphyModal'
import { useGiphy } from './hooks/useGiphy'

/**
 *
 */
function Giphy({ setSelectedFile }: { setSelectedFile: (event: any) => void }) {
  const { gifs } = useGiphy()
  const { openModal, setOpenModal, Modal } = useModal()
  const { trackGifSelected } = useTracking()

  return (
    <>
      <Row data-testid="giphy-picker">
        {gifs.slice(0, 4).map((gif: any, index: number) => (
          <Button
            key={`gif-${index}`}
            aria-pressed={false}
            onClick={() => {
              setSelectedFile(gif.src)
              trackGifSelected(gif.src)
            }}
          >
            <GiphyImage src={gif.src} alt={gif.alt} />
          </Button>
        ))}
        <Button
          key="gif-modal"
          aria-pressed={false}
          onClick={() => {
            setOpenModal(true)
          }}
        >
          <GiphyImage src={gifs[4].src} alt={gifs[5].alt} />
          <Overlay>
            <OverlayText>+{gifs.length - 5}</OverlayText>
          </Overlay>
        </Button>
      </Row>
      {openModal && (
        <Modal>
          <GiphyModal
            data-testid="giphy-modal"
            setOpenModal={setOpenModal}
            selectGif={setSelectedFile}
          />
        </Modal>
      )}
    </>
  )
}

export default Giphy
