import styled from 'styled-components'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import { blue, grayDark } from '@bufferapp/ui/style/colors'
import Text from '@bufferapp/ui/Text'

/*
 * STYLES FOR THE MODAL WINDOW
 */

export const Title = styled(Text)`
  margin-bottom: 12px;
`

export const Body = styled.div`
  align-self: flex-start;
  padding: 30px 56px 12px 56px;
`

export const MainArea = styled.div`
  max-width: 304px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    font-size: 14px;
    line-height: 24px;
    font-family: 'Roboto';
    font-weight: 400;
    color: ${grayDark};
  }
`

export const List = styled.ul`
  margin: 16px 0 16px 0;
  padding: 0;
`

export const ListItem = styled.li`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${grayDark};
  list-style: none;
  margin-bottom: 16px;
  margin-left: 7px;
`

export const CheckmarkBlue = styled(CheckmarkIcon)`
  fill: ${blue};
  margin-right: 7px;
  vertical-align: middle;
`
