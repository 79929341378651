import React from 'react'
import Button from '@bufferapp/ui/Button'

import { StyledOpenNewIcon, ButtonWrapper } from './styles'

interface Props {
  url: string
}

const GoToMailchimp: React.FC<Props> = ({ url }) => {
  const onGoTo = React.useCallback((url: string | null) => {
    if (url) {
      window.location.assign(url)
    }
  }, [])

  return (
    <ButtonWrapper>
      <Button
        type="primary"
        onClick={(): void => onGoTo(url)}
        label="I'm ready, take me to Mailchimp"
        icon={<StyledOpenNewIcon color="white" />}
        iconEnd
      />
    </ButtonWrapper>
  )
}

export default GoToMailchimp
