import React, { useEffect, useState } from 'react'

import type { HeaderBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import useImageUpload from '~/app/state/hooks/useImageUpload'
import {
  TextAreaWithLabel,
  FieldSet,
  UploadMedia,
  InputWithLabel,
} from '~/shared/components'
import { BLOCK_CATEGORIES } from '../../index'
import Layouts from './Layouts'
import { Label, LogoStyled } from './styles'

export const CHAR_LIMIT = 150

export type onSelectLayoutParams = {
  selectedLayout: string
  selectedBackgroundImage: string
}

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ESSENTIALS,
  type: 'HEADER',
  databaseType: 'StartPageBlockHeader',
  icon: {
    Component: HeaderIcon,
    color: '#FFFFFF',
  },
}

export const editButtonSettings = {
  label: 'Profile Image / Logo',
  contentField: null,
}

/**
 *
 */
function HeaderIcon({ block }: { block: HeaderBlock }) {
  const { logoMedia } = block
  const { url, altText } = logoMedia || {}

  return url ? (
    <LogoStyled url={url} alt={altText} width={40} height={40} />
  ) : null
}

/**
 *
 */
export function BlockForm({ block }: { block: HeaderBlock }) {
  const [backgroundUrl, setBackgroundUrl] = useState(
    block?.backgroundMedia?.url || '',
  )
  const [title, setTitle] = useState(block?.title || '')
  const [description, setDescription] = useState(block?.description || '')
  const [altText, setAltText] = useState(block?.logoMedia?.altText || '')
  const [layout, setLayout] = useState(block?.layout || 'ImageFullVertical')

  const { saveBlock, changes, setChanges } = useSaveBlock({ block })

  const {
    imageUrl: logoUrl,
    setSelectedFile: setSelectedLogoFile,
    imageUploading: logoUploading,
    errorUploading: logoError,
  } = useImageUpload(block?.logoMedia?.url || '')

  useEffect(() => {
    const valuesChanged =
      title !== block.title ||
      description !== block.description ||
      altText !== block?.logoMedia?.altText ||
      logoUrl !== block?.logoMedia?.url ||
      backgroundUrl !== block?.backgroundMedia?.url ||
      layout !== block.layout

    const hasError = description.length > CHAR_LIMIT

    if (valuesChanged && !hasError) {
      setChanges({
        title,
        description,
        logoMedia: {
          url: logoUrl,
          altText,
        },
        backgroundMedia: {
          url: backgroundUrl,
        },
        layout,
      })
    } else {
      setChanges(null)
    }
  }, [title, description, altText, logoUrl, backgroundUrl, layout])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    const hasNewLogoImage = changes?.logoMedia?.url !== block?.logoMedia?.url
    const hasNewBackgroundImage =
      changes?.backgroundMedia?.url !== block?.backgroundMedia?.url
    const layoutChanged = changes?.layout !== block.layout

    if (hasNewLogoImage || hasNewBackgroundImage || layoutChanged) {
      saveBlock()
    }
  }, [changes])

  const onSelectLayout = React.useCallback(
    ({ selectedLayout, selectedBackgroundImage }: onSelectLayoutParams) => {
      setLayout(selectedLayout)
      setBackgroundUrl(selectedBackgroundImage)
    },
    [],
  )

  return (
    <>
      <Label htmlFor="logo" type="label">
        Logo or personal photo
      </Label>
      <FieldSet id="logo">
        <UploadMedia
          smallPreview={logoUrl}
          setSelectedFile={setSelectedLogoFile}
          loading={logoUploading}
          errorUploading={logoError}
        />
        <InputWithLabel
          label="Alt Text"
          inputValue={altText}
          setInputValue={setAltText}
          saveOnBlur={saveOnBlur}
        />
      </FieldSet>
      <FieldSet>
        <TextAreaWithLabel
          label="Your business or personal name"
          id="textarea-name"
          textAreaValue={title}
          setTextAreaValue={setTitle}
          saveOnBlur={saveOnBlur}
        />
      </FieldSet>
      <FieldSet>
        <TextAreaWithLabel
          label="A few words about you or your business"
          id="textarea-description"
          placeholder="A few words..."
          textAreaValue={description}
          setTextAreaValue={setDescription}
          charLimit={CHAR_LIMIT}
          saveOnBlur={saveOnBlur}
        />
      </FieldSet>
      <Label type="p" aria-label="Header format">
        Header Format
      </Label>
      <Layouts
        layout={layout}
        backgroundImage={backgroundUrl}
        onSelect={onSelectLayout}
      />
    </>
  )
}
