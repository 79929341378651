type GiphyMedia = {
  src: string
  alt: string
}

export function useGiphy(): { gifs: GiphyMedia[] } {
  // Constants for now - We'll do a Giphy integration later

  const gifs = [
    {
      src: 'https://media0.giphy.com/media/l4KihuqeuJEi9qLSM/giphy-downsized.gif?cid=790b76112bf20688bc204d38f7dcb92a301e0701c210d4f4&rid=giphy.gif&ct=g',
      alt: 'Design 3D GIF',
    },
    {
      src: 'https://media0.giphy.com/media/3o7TKUfnQjVBh7vJWE/giphy-downsized.gif?cid=790b761156fbfebc4e5c1c0a67ab0e77995442721d9a2948&rid=giphy.gif&ct=g',
      alt: 'Flower Pattern GIF',
    },
    {
      src: 'https://media2.giphy.com/media/xThuWjC7yute751y0g/giphy.gif?cid=790b76117f9cab686023b715474d6410d284d6765907d066&rid=giphy.gif&ct=g',
      alt: 'Party pop GIF',
    },
    {
      src: 'https://media3.giphy.com/media/YrIoypb7Y4pCKCwiEE/giphy-downsized.gif?cid=790b761190ac94f4a2df28519288d174a58ada393d66e547&rid=giphy.gif&ct=g',
      alt: 'Fall Discover GIF',
    },
    {
      src: 'https://media0.giphy.com/media/xUOxeRITrMzqPwbPJ6/giphy-downsized.gif?cid=790b7611dfe60919f77180ab5358af947017a9595cdb4dbc&rid=giphy.gif&ct=g',
      alt: '3D Motion GIF',
    },
    {
      src: 'https://media1.giphy.com/media/U3qYN8S0j3bpK/giphy-downsized.gif?cid=790b7611a37f581ca2990105394abaacf6b8dea959f15750&rid=giphy.gif&ct=g',
      alt: 'Stars background GIF',
    },
    {
      src: 'https://media2.giphy.com/media/H75Lo6V4M5pWrDo8mZ/giphy-downsized.gif?cid=790b761181e3b892859a51aa84e7b5b9553f270ee1b26e86&rid=giphy.gif&ct=g',
      alt: 'Haha Hariz GIF',
    },
    {
      src: 'https://media4.giphy.com/media/jnxQEHbSQalrXkHUED/giphy.gif?cid=790b761169c25b8997a219d3befe873e0c07ce4ee62f4a8c&rid=giphy.gif&ct=g',
      alt: 'Stars GIF',
    },
    {
      src: 'https://media0.giphy.com/media/RkPUiD8uhXhCqartjk/giphy-downsized.gif?cid=790b76110101dda8488a07de64a8c8cf3b83873f1addf268&rid=giphy.gif&ct=g',
      alt: 'Art Glow GIF',
    },
    {
      src: 'https://media0.giphy.com/media/rGUv2lvlTIG71uryeh/giphy.gif?cid=790b761179dff908c4a2f2a9854407bdc7b0cec273b0cbe2&rid=giphy.gif&ct=g',
      alt: 'Blue And Yellow Illustration GIF',
    },
    {
      src: 'https://media3.giphy.com/media/CJIBkEUMvOwLiSrzmY/giphy.gif?cid=790b7611f392626f3a1010783e08c8abac32b1e91e90510d&rid=giphy.gif&ct=g',
      alt: 'Animated GIF',
    },
    {
      src: 'https://media1.giphy.com/media/3osxYliokw0RKomKcg/giphy-downsized.gif?cid=790b76111e9fcea1becc743c2262b9b21fde0c21be533745&rid=giphy.gif&ct=g',
      alt: 'Pink pop GIF',
    },
    {
      src: 'https://media0.giphy.com/media/xUOwGcu6wd0cXBj5n2/giphy-downsized.gif?cid=790b7611ffef871386a9996801fcbf48f8e22f8dc547d5e4&rid=giphy.gif&ct=g',
      alt: 'Palm Trees Palms GIF',
    },
    {
      src: 'https://media3.giphy.com/media/3o7bu2ptBhTiYHnG7K/giphy-downsized.gif?cid=790b76111067c1dc87f20bc3410958991dac190da09e7fd4&rid=giphy.gif&ct=g',
      alt: 'Summer Pastel GIF',
    },
    {
      src: 'https://media2.giphy.com/media/3o7btVliVicgB9N5mM/giphy-downsized.gif?cid=790b761160afaa4b2e1b43d74d0f33331df5009ed657e66d&rid=giphy.gif&ct=g',
      alt: 'Tired Sweet Dreams GIF',
    },
  ]
  return {
    gifs,
  }
}
