import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Notification from '@bufferapp/ui/Notification'

const NotificationWrapper = styled.div`
  height: 113px;
  width: 376px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
`

interface INotificationContext {
  setNotificationOptions: ({}) => void
}

export const NotificationContext = React.createContext<
  INotificationContext | undefined
>(undefined)

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notificationOptions, setNotificationOptions] = useState<any>()

  useEffect(() => {
    if (notificationOptions?.message) {
      setTimeout(() => {
        setNotificationOptions({ type: null, message: null })
      }, 3000)
    }
  }, [notificationOptions?.message])

  const value = React.useMemo(
    () => ({
      setNotificationOptions,
    }),
    [],
  )

  return (
    <NotificationContext.Provider value={value}>
      {notificationOptions?.message && (
        <NotificationWrapper>
          <Notification
            text={notificationOptions?.message}
            onClose={() => {
              setNotificationOptions({ type: null, message: null })
            }}
            type={notificationOptions?.type}
          />
        </NotificationWrapper>
      )}

      {children}
    </NotificationContext.Provider>
  )
}

function useNotificationContext() {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error('useNotificationContext is undefined')
  }
  return context
}

export { NotificationProvider, useNotificationContext }
