import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import {
  gray,
  grayLight,
  grayDark,
  grayDarker,
  blue,
  white,
  yellowLighter,
  yellow,
  yellowDarker,
} from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  padding: 0px 16px 16px;

  label {
    margin: 12px 0;
    display: block;
  }
`

export const Link = styled.a`
  margin: 0px 4px;
  color: ${blue};
  text-decoration: none;
`

export const HelpTextWrapper = styled.div<{
  highlighted: boolean
}>`
  ${(props): FlattenSimpleInterpolation => {
    const { highlighted } = props

    return css`
      padding: 11px 12px;
      border-radius: 4px;
      background-color: ${highlighted ? yellowLighter : grayLight};
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      color: ${highlighted ? yellowDarker : grayDarker};
      margin: 0px 0px 16px 0px;
      border: 1px solid ${highlighted ? yellow : gray};

      svg {
        height: 100%;
        flex-shrink: 0;
        color: ${highlighted ? yellowDarker : grayDark};
        fill: ${highlighted ? yellowDarker : grayDark};
        margin-top: 3px;
        margin-right: 8px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
    `
  }}
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  padding: 16px;
  background-color: ${white};
  border: 1px solid ${gray};
`

export const ImageWrapper = styled.img`
  height: 256px;
  border-radius: 4px;
  background-color: ${grayLight};
  margin-bottom: 32px;
`

export const List = styled.ol`
  margin: 0px 0px 0px 14px;
`

export const Item = styled.li`
  margin-bottom: 4px;
`

export const FeedsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
`

export const TextWrapper = styled.div`
  margin: 32px 0 16px;
  line-height: 35px;
`
