import React from 'react'
import {
  TextBold,
  TextItalic,
  TextUnderline,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
} from '@bufferapp/ui/Icon'
import {
  getPluginType,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  AlignToolbarButton,
} from '@udecode/plate'
import { useMyPlateEditorRef } from '../../config/types'
import { Separator } from '../../styles'

function BasicMarkToolbarButtons() {
  const editor = useMyPlateEditorRef()!

  return (
    <>
      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<TextBold />}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<TextItalic />}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<TextUnderline />}
      />
      <Separator />
      <AlignToolbarButton value="left" icon={<TextAlignLeft />} />
      <AlignToolbarButton value="center" icon={<TextAlignCenter />} />
      <AlignToolbarButton value="right" icon={<TextAlignRight />} />
    </>
  )
}

function ToolbarButtons() {
  return <BasicMarkToolbarButtons />
}

export default ToolbarButtons
