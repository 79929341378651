import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

export const FieldWrapper = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 34px;
    right: 10px;
    a {
      color: ${grayDark};
    }
  }
  input {
    line-height: 18px;
    padding-right: 32px;
  }
`

export const FieldSet = styled.div`
  background: white;
  margin: 0px 16px 16px;
  padding: 16px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;

  & > * {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

export const TextAreaOptions = styled.div`
  display: flex;
  justify-content: space-between;
`
