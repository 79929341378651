import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { MUTATION_PUBLISH } from '~/app/state/mutations'
import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import {
  type Blocks,
  type StartPage,
  generateHTML,
} from '@buffer-mono/sp-display'
import { useStartPageContext } from '~/modules/pages/state/context'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import { useAccountContext } from '~/modules/account/state/context'

import { useSplitEnabled } from '@bufferapp/features'

interface IError {
  message: string
  code: number
}
export type ErrorState = IError | null

export default function (): {
  publishChanges: (domain: string) => void
  response: object | null
  error: ErrorState
  loading: boolean
} {
  const { refetchAccount } = useAccountContext()
  const { startPageData, hasDomain } = useStartPageContext()
  const { trackUsernameSelected, trackUsernameUpdated } = useTracking()
  const { id: pageId, blocks, theme } = startPageData || {}
  const [error, setError] = useState<ErrorState>(null)

  const { isEnabled: isCommunityReportingFlagEnabled } = useSplitEnabled(
    'build-week-2023-community-report-flag-option',
  )

  const [
    publishChangesMutation,
    { data: response, error: mutationError, loading },
  ] = useMutation(MUTATION_PUBLISH)

  const publishChanges = (domain: string): void => {
    error && setError(null)

    // Username tracking
    if (hasDomain && domain !== startPageData?.domain) {
      trackUsernameUpdated(domain)
    } else if (!hasDomain) {
      trackUsernameSelected(domain)
    }

    const content = generateHTML({
      blocks: blocks as Blocks,
      theme,
      pageId,
      domain,
      isCommunityReportingFlagEnabled,
    })

    if (blocks && content) {
      publishChangesMutation({
        variables: {
          pageId,
          organizationId: startPageData?.organizationId,
          data: {
            domain,
            content,
          },
        },
        update(cache, { data }) {
          // skip updating the cache if page has not been published
          if (!data?.publishStartPage?.startPage?.isPublished) return

          const allStartPageData = cache.readQuery<StartPage>({
            query: QUERY_START_PAGE,
            variables: {
              pageId,
              organizationId: startPageData?.organizationId,
            },
          })

          // Check if the page was published before.
          // If not, the page has taken a slot so we need
          // to refresh the account data to get all 'canPublishStartPage'
          // values updated.
          const wasPublished = startPageData?.isPublished
          if (!wasPublished) {
            refetchAccount && refetchAccount()
          }

          cache.writeQuery({
            query: QUERY_START_PAGE,
            data: {
              startPage: {
                ...allStartPageData?.startPage,
                hasUnPublishedChanges: false,
                isPublished: true,
              },
            },
          })
        },
      }).catch((error) => {
        console.log(error)
      })
    }
    // @todo: trigger an error state here
  }

  useEffect(() => {
    if (response?.publishStartPage?.code === '400') {
      setError(response.publishStartPage)
    }
    if (mutationError) {
      setError({
        code: 500,
        message: 'Whoops! Something went wrong',
      })
    }
  }, [response, mutationError])

  return {
    publishChanges,
    response: response ? { ...response } : null,
    error,
    loading,
  }
}
