import * as ButtonLink from './ButtonLink'
import * as Header from './Header'
import * as IconLinks from './IconLinks'
import * as ImageLink from './ImageLink'
import * as SubHeading from './SubHeading'
import * as Text from './Text'
import * as Video from './Video'
import * as Spotify from './Spotify'
import * as ImageLinksGrid from './ImageLinksGrid'
import * as Feed from './Feed'
import * as FeedPost from './FeedPost'
import * as Mailchimp from './Mailchimp'

export type BlockSettings = {
  category: string
  type: string
  databaseType: string
  icon: {
    Component: any
    color: string
  }
  comingSoon?: boolean
  isNew?: boolean
  unique?: boolean
  onlyForNewBuffer?: boolean
}

export type BlockAPI = {
  settings: BlockSettings
  editButtonSettings: {
    label: string
    contentField: string
  }
  addButtonSettings?: {
    label: string
    description: string
  }
}

export const Blocks = {
  ButtonLink,
  Header,
  IconLinks,
  ImageLink,
  SubHeading,
  Text,
  Video,
  Spotify,
  ImageLinksGrid,
  Feed,
  FeedPost,
  Mailchimp,
}

export function getBlockByDatabaseType(typename: string) {
  let result = null

  Object.keys(Blocks).forEach((blockName: string) => {
    const block = (Blocks as any)[blockName]
    const { databaseType } = block.settings

    if (databaseType === typename) {
      result = block
    }
  })

  return result
}

export function getBlockByBlockName(blockName: string) {
  const block = (Blocks as any)[blockName]
  if (!block) {
    console.error(
      '[getSettingsForBlockName] No block was found for blockname: ',
      blockName,
    )
    return null
  }

  return block
}
