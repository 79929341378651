import React from 'react'
import SparklesIcon from '@bufferapp/ui/Icon/Icons/Sparkles'
import CaretDownIcon from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretUpIcon from '@bufferapp/ui/Icon/Icons/CaretUp'

import useToggle from '~/shared/hooks/useToggle'

import {
  Wrapper,
  TipButton,
  TipContent,
  TipText,
  Label,
  Text,
  Description,
} from './styles'

const VIDEO_URL =
  'https://buffer-start-page.s3.amazonaws.com/tip-latest-youtube-video.mp4'
const VIDEO_IMG =
  'https://buffer-start-page.s3.amazonaws.com/tip-latest-youtube-video-poster.png'

/**
 *
 */
function Tip() {
  const { value: isOpened, onToggle } = useToggle(false)

  return (
    <Wrapper data-testid="tip">
      <TipButton onClick={onToggle}>
        <TipContent>
          <SparklesIcon size="large" />
          <TipText>
            <Label>Pro tip</Label>
            <Text>Show your channel's latest video</Text>
          </TipText>
        </TipContent>
        {isOpened ? <CaretDownIcon /> : <CaretUpIcon />}
      </TipButton>
      {isOpened && (
        <Description>
          <ol>
            <li>On desktop, go to your YouTube channel</li>
            <li>Navigate to the "Uploads" section</li>
            <li>Right click on the "Play All" button and copy the link</li>
            <li>Paste the link in the "Video link" field on top</li>
          </ol>
          <video
            preload="none"
            width="395"
            height="273"
            poster={VIDEO_IMG}
            controls
          >
            <source src={VIDEO_URL} type="video/mp4" />
          </video>
        </Description>
      )}
    </Wrapper>
  )
}

export default Tip
