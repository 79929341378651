import React from 'react'
import { FieldSet } from '~/shared/components'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import styled from 'styled-components'
import useModal from '~/shared/hooks/useModal'
import { useStartPageContext } from '~/modules/pages/state/context'
import useUnpublish from '~/modules/pages/hooks/useUnpublish'
import { ConfirmationModal } from './ConfirmationModal'

/**
 *
 */
export const Space = styled.div`
  margin-top: 8px;
`

/**
 *
 */
export function Unpublish() {
  const { openModal, setOpenModal, Modal } = useModal()
  const { startPageData } = useStartPageContext()
  const { unpublishPage, loading: unpublishing } = useUnpublish()

  const onUnpublish = () => {
    unpublishPage()
    onCloseModal()
  }

  const onOpenModal = React.useCallback(() => {
    setOpenModal(true)
  }, [setOpenModal])

  const onCloseModal = React.useCallback(() => {
    setOpenModal(false)
  }, [setOpenModal])

  return (
    <>
      <FieldSet>
        <Text htmlFor="domain-input" type="label">
          Danger Area
        </Text>
        <Space />
        <Button
          type="secondary"
          label={unpublishing ? 'Unpublishing...' : 'Unpublish my page'}
          disabled={!startPageData?.isPublished || unpublishing}
          onClick={onOpenModal}
          fullWidth
        />
      </FieldSet>
      {openModal && (
        <Modal>
          <ConfirmationModal onContinue={onUnpublish} onCancel={onCloseModal} />
        </Modal>
      )}
    </>
  )
}
