export default function updateModalStyle(): {
  moveModalToBack: () => void
  resetModalStyle: () => void
} {
  const modal = document.getElementById('modal-wrapper')
  const dropdown = document.getElementById('domain-dropdown')
  const navigationElement = document.getElementsByTagName('nav')[0]

  const elements = [modal, navigationElement, dropdown]
  // when triggering the appShell modal on top of an existing modal
  // we're using a helper class to set the z-index back to 0 to avoid stacking issues
  function moveModalToBack(): void {
    elements.forEach(
      (element) => element && element.classList.add('reset-z-index'),
    )
  }

  // using this function to remove the reset classes when the component is re-rendered
  function resetModalStyle(): void {
    elements.forEach(
      (element) => element && element.classList.remove('reset-z-index'),
    )
  }

  return {
    moveModalToBack,
    resetModalStyle,
  }
}
