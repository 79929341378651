import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;

  h3 {
    padding: 24px 16px 16px;
  }
`

export const QuestionBox = styled.div`
  background-color: #f5f5f5;
  padding: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`
