import React from 'react'
import CaretDownIcon from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretUpIcon from '@bufferapp/ui/Icon/Icons/CaretUp'
import GoToMailchimp from '../../shared/components/GoToMailchimp'

import useToggle from '~/shared/hooks/useToggle'

import {
  Wrapper,
  AccordionButton,
  AccordionTitle,
  AccordionHeader,
  Text,
  Description,
} from './styles'

/**
 *
 */

function NoHaveAccount(): React.JSX.Element {
  const { value: isOpened, onToggle } = useToggle(false)

  return (
    <Wrapper data-testid="have-account">
      <AccordionButton
        onClick={onToggle}
        data-testid="accordion-button-nohaveaccount"
      >
        <AccordionHeader>
          <AccordionTitle>
            <Text>
              <b>Not yet,</b> let&apos;s get set up!
            </Text>
          </AccordionTitle>
        </AccordionHeader>
        {isOpened ? <CaretDownIcon /> : <CaretUpIcon />}
      </AccordionButton>
      {isOpened && (
        <Description data-testid="description-nohaveaccount">
          <Text>
            Here&apos;s a 5 step guide to get set up in Mailchimp so you can
            start to capture emails on your StartPage:
          </Text>
          <ul>
            <li>1. Create a MailChimp account</li>
            <li>2. Verify your email when prompted</li>
            <li>3. Select GDPR Compliance </li>
            <li>4. Go to Audience and create your first Form</li>
            <li>5. Copy your form’s HTML code and, come back here</li>
          </ul>
          <GoToMailchimp url={'https://login.mailchimp.com/signup'} />
        </Description>
      )}
    </Wrapper>
  )
}

export default NoHaveAccount
