import styled from 'styled-components'
import { grayDarker, redDark } from '@bufferapp/ui/style/colors'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  input {
    margin: 0;
    margin-bottom: 12px;
  }

  p {
    margin-left: 8px;
    color: ${grayDarker};
    font-weight: ${fontWeightMedium};
    position: absolute;
    top: 7px;
    right: 8px;
  }
`

export const Error = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 8px 0 0 0;
  font-weight: ${fontWeightMedium};
  color: ${redDark};
  line-height: 16px;
  padding-bottom: 8px;

  svg {
    margin-right: 4px;
  }
`
