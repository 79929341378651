import styled from 'styled-components'
import Link from '@bufferapp/ui/Link'
import Text from '@bufferapp/ui/Text'
import OpenNewIcon from '@bufferapp/ui/Icon/Icons/OpenNew'

export const StyledLink = styled(Link)`
  color: black;
`

export const StyledOpenNewIcon = styled(OpenNewIcon)`
  margin-left: 5px;
  vertical-align: text-bottom;
`

export const StyledText = styled(Text)`
  margin-top: 30px;
`
