import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useStartPageContext } from '~/modules/pages/state/context'
import { useAccountContext } from '~/modules/account/state/context'
import { MUTATION_UNPUBLISH } from '../state/mutations'

interface IError {
  message: string
  code: number
}
export type ErrorState = IError | null

export default function useUnpublish() {
  const { refetchAccount } = useAccountContext()
  const { startPageData } = useStartPageContext()
  const { id: pageId, organizationId } = startPageData || {}
  const [error, setError] = useState<ErrorState>(null)
  const [
    unpublishPageMutation,
    { data: response, error: mutationError, loading },
  ] = useMutation(MUTATION_UNPUBLISH)

  const unpublishPage = () => {
    unpublishPageMutation({
      variables: {
        pageId,
        organizationId,
      },
      update() {
        refetchAccount && refetchAccount()
      },
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (response?.unPublishStartPage?.code === '400') {
      setError(response.unPublishStartPage)
    }
    if (mutationError) {
      setError({
        code: 500,
        message: 'Whoops! Something went wrong',
      })
    }
  }, [response, mutationError])

  return {
    unpublishPage,
    response: response ? { ...response } : null,
    error,
    loading,
  }
}
