import styled from 'styled-components'

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
`

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Wide = styled.div`
  width: 100%;

  & > * {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 16px 16px;
`

export const Image = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 4px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Left = styled.div`
  svg {
    margin-right: 8px;
  }
`
