import React from 'react'
import styled from 'styled-components'

const TransparentButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`
export function IconButton({
  onClick,
  children,
}: {
  onClick: () => void
  children: React.ReactNode
}) {
  return <TransparentButton onClick={onClick}>{children}</TransparentButton>
}
