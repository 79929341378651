import { gql } from '@apollo/client'

export const QUERY_START_PAGES = gql`
  query startPages($organizationId: String!) {
    startPages(organizationId: $organizationId) {
      ... on StartPages {
        startPages {
          id
          domain
          organizationId
          isPublished
          title
          blocks {
            ... on StartPageBlockHeader {
              logoMedia {
                url
                altText
              }
            }
          }
        }
      }
      ... on CommonError {
        code
        success
      }
    }
  }
`

export const QUERY_START_PAGE = gql`
  query startPage($organizationId: String!, $pageId: String!) {
    startPage(organizationId: $organizationId, pageId: $pageId) {
      ... on StartPage {
        id
        domain
        title
        hasUnPublishedChanges
        organizationId
        isPublished
        title
        theme {
          id
          globalStyles {
            backgroundColor
            textColor
          }
          blockStyles {
            blockType
            backgroundColor
            secondaryBackgroundColor
            textColor
            secondaryTextColor
            corners
            fontFamily
            secondaryFontFamily
            border
          }
        }
        blocks {
          id
          pageId
          order
          hidden
          createdAt
          ... on StartPageBlockHeader {
            backgroundMedia {
              url
              altText
            }
            logoMedia {
              url
              altText
            }
            title
            description
            overlay
            layout
          }
          ... on StartPageBlockIconLinks {
            links {
              url
              icon
              order
            }
          }
          ... on StartPageBlockSubHeading {
            text
          }
          ... on StartPageBlockText {
            text
            htmlText
            htmlJson
          }
          ... on StartPageBlockButtonLink {
            label
            url
          }
          ... on StartPageBlockVideo {
            media {
              url
              altText
            }
          }
          ... on StartPageBlockImageLink {
            caption
            url
            media {
              url
              altText
            }
          }
          ... on StartPageBlockSpotify {
            media {
              url
            }
            layout
          }
          ... on StartPageBlockImageLinksGrid {
            links {
              url
              media {
                url
                altText
              }
              order
              id
            }
          }
          ... on StartPageBlockFeed {
            title
            children {
              id
              order
              hidden
              createdAt
              ... on StartPageBlockFeedPost {
                caption
                title
                text
                label
                url
                media {
                  url
                  altText
                }
              }
            }
          }
          ... on StartPageBlockMailchimp {
            htmlText
          }
        }
      }
      ... on CommonError {
        code
        success
      }
    }
  }
`
