import { env } from '~/env'

export const BASE_URL =
  env.VITE_BACKEND_MODE === 'development' ? '.startpage.dev' : '.start.page'

export const MAX_SIZES = {
  jpg: 10 * 1024 * 1024,
  jpeg: 10 * 1024 * 1024,
  gif: 3 * 1024 * 1024,
  png: 10 * 1024 * 1024,
}

export const buttonCombinations = [
  { id: 'black-white', backgroundColor: '#232323', textColor: '#FFFFFF' },
  { id: 'orange-white', backgroundColor: '#FF7A00', textColor: '#FFFFFF' },
  { id: 'cyan-black', backgroundColor: '#06EDFF', textColor: '#232323' },
  { id: 'blue-white', backgroundColor: '#2C4BFF', textColor: '#FFFFFF' },
  { id: 'sand-white', backgroundColor: '#E6B333', textColor: '#FFFFFF' },
  { id: 'teal-white', backgroundColor: '#21426A', textColor: '#FFFFFF' },
  { id: 'green-white', backgroundColor: '#87C221', textColor: '#FFFFFF' },
  { id: 'cherry-white', backgroundColor: '#E0364F', textColor: '#FFFFFF' },
  { id: 'peach-black', backgroundColor: '#FFE2D5', textColor: '#232323' },
]

export const fontPacks = [
  {
    id: 'montserrat',
    header: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  },
  {
    id: 'playfair-display-source-sans-pro',
    header: 'Playfair Display, serif',
    body: 'Source Sans Pro, sans-serif',
  },
  {
    id: 'oswald-poppins',
    header: 'Oswald, sans-serif',
    body: 'Poppins, sans-serif',
  },
  {
    id: 'roboto-slab',
    header: 'Roboto Slab, serif',
    body: 'Roboto Slab, serif',
  },
  {
    id: 'quicksand-inter',
    header: 'Quicksand, sans-serif',
    body: 'Inter, sans-serif',
  },
  {
    id: 'libre-baskerville-roboto',
    header: 'Libre Baskerville, serif',
    body: 'Roboto, sans-serif',
  },
  {
    id: 'raleway-cardo',
    header: 'Raleway, sans-serif',
    body: 'Cardo, serif',
  },
  {
    id: 'amatic-sc-vollkorn',
    header: 'Amatic SC, cursive',
    body: 'Vollkorn, serif',
  },
  {
    id: 'lora-montserrat',
    header: 'Lora, serif',
    body: 'Montserrat, sans-serif',
  },
]

export const HC_UTM_PARAMS =
  'utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'
