import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Youtube from '@bufferapp/ui/Icon/Icons/Youtube'

import { InputWithLabel, FieldSet } from '~/shared/components'
import type { VideoBlock } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import { BLOCK_CATEGORIES } from '../../index'
import Tip from './Tip'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.MEDIA,
  type: 'VIDEO',
  databaseType: 'StartPageBlockVideo',
  icon: {
    Component: Youtube,
    color: '#e0364f',
  },
}

export const addButtonSettings = {
  label: 'YouTube Video',
  description: 'Say it with motion',
}

/**
 *
 */
export const editButtonSettings = {
  label: 'YouTube Video',
  contentField: null,
}

const Wrapper = styled.div`
  padding-bottom: 16px;
`

/**
 *
 */
export function BlockForm({ block }: { block: VideoBlock }) {
  const [videoBlock, setVideoBlock] = useState(() => block)
  const [linkValue, setLinkValue] = useState(() => block?.media?.url || '')

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: videoBlock,
  })

  useEffect(() => {
    if (linkValue !== block?.media?.url) {
      setChanges({
        media: {
          url: linkValue,
        },
      })
    } else setChanges(null)
  }, [linkValue])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !videoBlock?.id) {
      setVideoBlock(response.addBlock.block)
    }
  }, [response])

  return (
    <Wrapper>
      <FieldSet>
        <InputWithLabel
          label="YouTube Video URL"
          inputValue={linkValue}
          setInputValue={setLinkValue}
          openInNewTab
          saveOnBlur={saveOnBlur}
        />
      </FieldSet>
      <Tip />
    </Wrapper>
  )
}
