import { useMutation } from '@apollo/client'
import { MUTATION_DELETE_BLOCK } from '~/app/state/mutations'
import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import { useStartPageContext } from '~/modules/pages/state/context'
import type { AnyBlock, StartPage, Blocks } from '@buffer-mono/sp-display'
import useTracking from '~/shared/hooks/useTracking/useTracking'

type UseDeleteBlockProps = {
  blockId: string
}

type GetUpdatedBlocksParam = {
  blockId: string
  existingBlocks: Blocks
}

export const getUpdatedBlocks = ({
  blockId,
  existingBlocks,
}: GetUpdatedBlocksParam) => {
  const newBlocks: AnyBlock[] = []
  existingBlocks.forEach((block) => {
    if (block.id === blockId) return
    return newBlocks.push(block)
  })

  return newBlocks
}

// We'll use apollo client update method to update the state instead of updating manually
// for now. If we implement the visitor flow, we'll bring this back and likely
// remove apollo client.

export default function ({ blockId }: UseDeleteBlockProps) {
  const { startPageData, setStartPageData } = useStartPageContext()
  const { trackBlockDeleted } = useTracking()
  const [saveChangesToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_DELETE_BLOCK)

  const deleteBlock = () => {
    if (blockId && startPageData?.blocks) {
      const blockToDelete = startPageData.blocks.find((block) => {
        if (block.id === blockId) return block
      })

      const newBlockArray = getUpdatedBlocks({
        blockId,
        existingBlocks: startPageData.blocks,
      })

      // update state
      setStartPageData({
        ...startPageData,
        hasUnPublishedChanges: true,
        blocks: newBlockArray,
      })

      // save changes to DB
      saveChangesToDB({
        variables: {
          blockId,
          organizationId: startPageData?.organizationId,
        },
        update(cache, { data: { deleteBlock } }) {
          const allStartPageData = cache.readQuery<StartPage>({
            query: QUERY_START_PAGE,
            variables: {
              pageId: startPageData?.id,
              organizationId: startPageData?.organizationId,
            },
          })
          cache.writeQuery({
            query: QUERY_START_PAGE,
            data: {
              startPage: {
                ...allStartPageData?.startPage,
                hasUnPublishedChanges: true,
                blocks: deleteBlock.blocks,
              },
            },
          })
        },
      })
        .then(() => {
          if (blockToDelete) trackBlockDeleted(blockToDelete)
        })
        .catch((error) => {
          console.log(error)
          // @todo: trigger an error state & refetch data
        })
    }
    // @todo: trigger an error state here
  }

  return {
    deleteBlock,
    response: response ? { ...response } : null,
    mutationError,
    loading,
  }
}
