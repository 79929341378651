import React from 'react'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'
import { Wrapper, QuestionBox, ButtonContainer } from './styles'

interface IDeleteBlockModal {
  setOpenModal: (openModal: boolean) => void
  deleteBlock: () => void
  onReturnClick?: () => void
}

export function DeleteBlockModal({
  setOpenModal,
  deleteBlock,
  onReturnClick,
}: IDeleteBlockModal) {
  return (
    <Wrapper>
      <Text type="h3">Are you sure you want to delete this block?</Text>
      <QuestionBox>
        <Text type="p">
          You will lose this content if you confirm but not to worry! It only
          takes a few seconds to add new content to your Start Page 🙌
        </Text>
      </QuestionBox>
      <ButtonContainer>
        <Button
          type="text"
          onClick={() => {
            setOpenModal(false)
          }}
          label="Cancel"
        />
        <Button
          type="primary"
          onClick={() => {
            deleteBlock()
            setOpenModal(false)
            if (onReturnClick) onReturnClick()
          }}
          label="Delete"
        />
      </ButtonContainer>
    </Wrapper>
  )
}
