import type { StartPageData } from '@buffer-mono/sp-display'
import { Client } from '@bufferapp/buffer-tracking-browser-ts'

import type {
  ITrackingAccountMetadata,
  ITrackingSPMetadata,
} from './useTrackingTypes'

export function setSPMetadata(
  startPageData: StartPageData | null,
): ITrackingSPMetadata | null {
  if (
    !startPageData ||
    !startPageData.organizationId ||
    !startPageData.blocks
  ) {
    return null
  }

  return {
    totalBlocks: startPageData.blocks.length,
    preSignup: false,
  }
}

export function setAccountMetadata(
  orgId: string | undefined,
): ITrackingAccountMetadata | null {
  if (!orgId) {
    return null
  }

  return {
    product: 'start_page',
    organizationId: orgId,
    clientName: Client.PublishWeb,
  }
}

export function setAtEventMetaData(accountData: any | null): any | null {
  const commonTrackingProperties =
    accountData?.currentOrganization?.commonTrackingProperties || null
  return {
    atEventBillingState: commonTrackingProperties?.atEventBillingState || null,
    atEventBillingPlan: commonTrackingProperties?.atEventBillingPlan || null,
    atEventBillingCycle: commonTrackingProperties?.atEventBillingCycle || null,
    atEventBillingGateway:
      commonTrackingProperties?.atEventBillingGateway || null,
    atEventIsNewBuffer:
      commonTrackingProperties &&
      'atEventIsNewBuffer' in commonTrackingProperties
        ? commonTrackingProperties.atEventIsNewBuffer
        : null,
    atEventChannelQty: commonTrackingProperties?.atEventChannelQty || null,
  }
}
