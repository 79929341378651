import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import {
  QUERY_START_PAGE,
  QUERY_START_PAGES,
} from '~/modules/pages/state/queries'
import type { StartPageData } from '@buffer-mono/sp-display'
import useCreateStartPage from '~/modules/pages/hooks/useCreateStartPage'
import { useAccountContext } from '~/modules/account/state/context'

export const START_PAGES_LIMIT = 25

/**
 *
 */
type NewStartPageType = {
  template: string
  title?: string
}

export const StartPageContext = React.createContext<
  | {
      hasDomain: boolean
      startPageData: StartPageData | null
      startPageError: boolean
      setStartPageData: ({}) => void
      startPagesData: StartPageData[]
      startPagesError: boolean
      loadStartPage: (startPageId: string) => void
      setStartPageIdFromPath: (startPageId: string) => void
      createNewStartPage: ({ template, title }: NewStartPageType) => void
    }
  | undefined
>(undefined)

/**
 *
 */
function useStartPageContext() {
  const context = useContext(StartPageContext)
  if (context === undefined) {
    throw new Error('useStartPageContext is undefined')
  }
  return context
}

/**
 *
 */
function StartPageProvider({ children }: { children: React.ReactNode }) {
  const [startPages, setStartPages] = useState<StartPageData[] | null>(null)
  const [startPageData, setStartPageData] = useState<StartPageData | null>(null)
  const [startPageIdFromPath, setStartPageIdFromPath] = useState<string | ''>(
    '',
  )
  // Every time a new page is created the backend assigns the
  // organization ID as the initial domain to avoid empty domains
  // and be able to have a unique index on the DB.
  // We request users to set a domain if we notice that the current
  // domain equals to the default one (organization ID).
  const [hasDomain, setHasDomain] = useState<boolean>(false)

  const { accountData, refetchAccount } = useAccountContext()
  const navigate = useNavigate()

  const [
    queryStartPages,
    {
      data: startPagesQueryData,
      error: startPagesQueryError,
      refetch: refetchStartPages,
    },
  ] = useLazyQuery(QUERY_START_PAGES)

  const [
    queryStartPage,
    { data: startPageQueryData, error: startPageQueryError },
  ] = useLazyQuery(QUERY_START_PAGE)

  const {
    createStartPage,
    response: createStartPageResponse,
    loading: createStartPageLoading,
  } = useCreateStartPage()

  useEffect(() => {
    if (!accountData) return
    queryStartPages({
      variables: {
        organizationId: accountData.currentOrganization?.id,
      },
    })
  }, [accountData])

  useEffect(() => {
    if (!startPagesQueryData) return
    const startPages = startPagesQueryData.startPages?.startPages || null
    setStartPages(startPages)
  }, [startPagesQueryData])

  useEffect(() => {
    if (!startPages || startPageData) return
    // temporary fix to handle routes being redirected to page when hitting onboarding
    const isOnBoarding = window.location.pathname === '/onboarding'

    const hasStartPages = startPages.length > 0

    if (hasStartPages && !isOnBoarding) {
      // checks if string from path is a valid sp id
      const validatedStartPageId = startPages.find(
        ({ id }) => id == startPageIdFromPath,
      )
        ? startPageIdFromPath
        : ''

      // Set first start page as the selected one.
      const firstStartPage = validatedStartPageId || startPages[0].id
      firstStartPage && loadStartPage(firstStartPage)

      // if no id is specified or not valid navigate to first start page ID
      if (!startPageIdFromPath || !validatedStartPageId) {
        navigate(`/${firstStartPage}`)
      }
    } else {
      navigate('/onboarding')
    }
  }, [startPages])

  useEffect(() => {
    if (!startPageQueryData) return
    setStartPageData({ ...startPageQueryData.startPage })
  }, [startPageQueryData])

  useEffect(() => {
    if (!startPageData) return

    const { domain, organizationId } = startPageData
    const hasDomain = !!domain && domain !== organizationId
    setHasDomain(hasDomain)
  }, [startPageData])

  useEffect(() => {
    if (!createStartPageResponse) return

    setStartPageData({ ...createStartPageResponse })
    loadStartPage(createStartPageResponse.id)
    refetchAccount && refetchAccount()
    refetchStartPages && refetchStartPages()

    navigate(`/${createStartPageResponse.id}`)
  }, [createStartPageResponse])

  const createNewStartPage = ({ template, title }: NewStartPageType) => {
    if (!accountData || !startPages) return

    createStartPage({
      config: { template, title },
      organizationId: accountData.currentOrganization.id,
    })
  }

  const loadStartPage = (startPageId: string) => {
    queryStartPage({
      variables: {
        pageId: startPageId,
        organizationId: accountData.currentOrganization?.id,
      },
    })
  }

  const value = {
    hasDomain,
    startPageData,
    startPageError: !!startPageQueryError,
    setStartPageData,
    startPagesData: startPages || [],
    startPagesError: !!startPagesQueryError,
    createNewStartPage,
    loadStartPage,
    setStartPageIdFromPath,
  }

  return (
    <StartPageContext.Provider value={value}>
      {children}
    </StartPageContext.Provider>
  )
}

export { StartPageProvider, useStartPageContext }
