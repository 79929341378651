import { gql } from '@apollo/client'

export const QUERY_STATISTICS = gql`
  query startPage($organizationId: String!, $pageId: String!) {
    startPage(organizationId: $organizationId, pageId: $pageId) {
      ... on StartPage {
        id
        statistics {
          allTimeViews
          dailyViews
          weeklyViews
          linksClicks {
            block {
              id
            }
            clicks
            link
          }
        }
      }
    }
  }
`

export const QUERY_THEMES = gql`
  query startPageThemes {
    startPageThemes {
      themes {
        id
        globalStyles {
          backgroundColor
          textColor
        }
        blockStyles {
          blockType
          backgroundColor
          secondaryBackgroundColor
          textColor
          secondaryTextColor
          textAlign
          corners
          fontFamily
          secondaryFontFamily
          border
        }
      }
    }
  }
`

export const QUERY_S3_SIGNED_URL = gql`
  query s3SignedUrl($fileName: String!, $fileType: String!) {
    s3SignedUrl(fileName: $fileName, fileType: $fileType)
  }
`
