import { useMutation } from '@apollo/client'
import { MUTATION_CREATE_START_PAGE } from '~/app/state/mutations'
import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import { useEffect, useState } from 'react'

export default function () {
  const [
    createStartPageMutation,
    { data: response, error: mutationError, loading },
  ] = useMutation(MUTATION_CREATE_START_PAGE, {
    refetchQueries: [QUERY_START_PAGE],
  })
  const [error, setError] = useState(false)

  const createStartPage = ({
    config,
    organizationId,
  }: {
    config: { template: string; title?: string }
    organizationId: string
  }) => {
    if (error) {
      setError(false)
    }
    createStartPageMutation({
      variables: {
        organizationId,
        config,
      },
    })
  }

  useEffect(() => {
    if (response?.createStartPage.error || mutationError) {
      setError(true)
    }
  }, [response, mutationError])

  return {
    createStartPage,
    response: response?.createStartPage?.startPage || null,
    error,
    loading,
  }
}
