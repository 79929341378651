import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Button = styled.button`
  position: relative;
  width: 72px;
  background: #f5f5f5;
  border-radius: 6px;
  cursor: pointer;
  border: none;
`

export const GiphyImage = styled.img`
  border-radius: 6px;
  width: 72px;
  height: 72px;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  z-index: 2;
  cursor: pointer;
`

export const OverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
  font-weight: 700;
  color: white;
  border-radius: 6px;
  transform: translate(-50%, -50%);
`
