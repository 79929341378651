import React from 'react'

import type { MailchimpBlock } from '@buffer-mono/sp-display'

import { BLOCK_CATEGORIES } from '../../index'
import MailIcon from '@bufferapp/ui/Icon/Icons/Mail'
import Text from '@bufferapp/ui/Text'

import { Wrapper, ShowMoreLink } from './styles'
import HaveAccount from './components/HaveAccount'
import NoHaveAccount from './components/NoHaveAccount'
import HelpSection from './components/HelpSection'
import FormArea from './components/FormArea'

const MAILCHIMP_IMAGE_URL =
  'https://buffer-start-page.s3.amazonaws.com/mailchimp.png'

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ADVANCED,
  type: 'MAILCHIMP',
  databaseType: 'StartPageBlockMailchimp',
  isNew: true,
  onlyForNewBuffer: true,
  icon: {
    Component: MailIcon,
    color: '#ffe01b',
  },
}

export const addButtonSettings = {
  label: 'Email Sign up Form',
  description:
    'Easily start building a mailing list with a signup form powered by Mailchimp',
}

export const editButtonSettings = {
  label: 'Email Sign up Form',
  contentField: 'htmlText',
}

/**
 *
 */

export function BlockForm({
  block,
}: {
  block: MailchimpBlock
}): React.JSX.Element {
  const [helpExpanded, setHelpExpanded] = React.useState(false)

  const onToggleShowHelp = React.useCallback(() => {
    setHelpExpanded((currentValue) => !currentValue)
  }, [setHelpExpanded])

  return (
    <>
      <Wrapper>
        {/* HEADER */}
        <Text type="p">
          Mailchimp is ideal for Small Businesses and Creators who want to build
          an email list to directly connect with their audience.&nbsp;
          <ShowMoreLink onClick={onToggleShowHelp}>
            {helpExpanded ? 'Show less' : 'Learn more'}
          </ShowMoreLink>
        </Text>

        {/* HELP SECTION */}
        {helpExpanded && <HelpSection />}

        <img
          src={MAILCHIMP_IMAGE_URL}
          width="420px"
          alt="Grow your audience with Mailchimp"
        />

        {/* HAVE NO HAVE ACCOUNT ACCORDIONS */}
        <Text type="p">
          <b>Do you already have a Mailchimp account?</b>
        </Text>
        <NoHaveAccount />
        <HaveAccount />
      </Wrapper>

      {/* TEXT AREA TO PASTE THE MAILCHIMP EMBEDDED FORM */}
      <FormArea block={block} />
    </>
  )
}
