import { useState, useEffect } from 'react'
import axios from 'axios'
import { useLazyQuery } from '@apollo/client'
import { QUERY_S3_SIGNED_URL } from '~/app/state/queries'
import getFileTypeFromPath from '~/shared/utils/getFileTypeFromPath'

import { MAX_SIZES } from '~/shared/constants'
import type { NewMediaProps } from './useSaveBlock'

export type SelectedFile = File | undefined

export default function (url: string) {
  const [selectedFile, setSelectedFile] = useState<SelectedFile>(undefined)
  const [imageUrl, setImageUrl] = useState(() => url)
  const [imageUploading, setImageUploading] = useState(false)
  const [errorUploading, setErrorUploading] = useState(false)
  const [s3SignedUrl, { loading, data }] = useLazyQuery(QUERY_S3_SIGNED_URL)

  const uploadToS3 = (newMedia: NewMediaProps) => {
    setImageUploading(true)
    if (newMedia.file) {
      const ext = getFileTypeFromPath(newMedia.file?.name)
      if (
        ext in MAX_SIZES &&
        newMedia.file.size > MAX_SIZES[ext as keyof typeof MAX_SIZES]
      ) {
        setImageUploading(false)
        setErrorUploading(true)
      }
    }

    axios
      .put(newMedia.s3SignedUrl, newMedia.file, {
        headers: {
          'Content-Type': newMedia.file?.type,
          // Mark as original so it will be scrubbed of EXIF data
          'x-amz-meta-original': 'true',
        },
      })
      .then(() => {
        const signedUrl = new URL(newMedia.s3SignedUrl)
        setImageUrl(signedUrl.origin + signedUrl.pathname)
        setImageUploading(false)
      })
  }

  useEffect(() => {
    if (selectedFile) {
      s3SignedUrl({
        variables: {
          fileName: selectedFile.name,
          fileType: selectedFile.type,
        },
      })
    }
  }, [selectedFile])

  useEffect(() => {
    if (data && data.s3SignedUrl && !loading) {
      setErrorUploading(false)
      uploadToS3({ s3SignedUrl: data.s3SignedUrl, file: selectedFile })
    }
  }, [data])

  return { imageUrl, setSelectedFile, imageUploading, errorUploading }
}
