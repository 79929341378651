import React, { useEffect } from 'react'
import { serializeHtml, useEditorState } from '@udecode/plate'

function GetFormattedHTML({
  setGeneretadeHtml,
}: {
  setGeneretadeHtml: (value: string) => void
}) {
  const editor = useEditorState()
  const html = serializeHtml(editor, {
    nodes: editor.children,
  })

  useEffect(() => {
    setGeneretadeHtml(html)
  }, [html])

  return <></>
}

export default GetFormattedHTML
