import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ImageIcon from '@bufferapp/ui/Icon/Icons/Image'

import {
  TextAreaWithLabel,
  InputWithLabel,
  FieldSet,
  UploadMedia,
  Tip,
} from '~/shared/components'
import { type ImageLinkBlock, getResizeURL } from '@buffer-mono/sp-display'
import useSaveBlock from '~/app/state/hooks/useSaveBlock'
import useImageUpload from '~/app/state/hooks/useImageUpload'
import catchBlockedLinks from '~/shared/utils/catchBlockedLinks'
import { BLOCK_CATEGORIES } from '../../index'

const MediaWrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
`

const StyledImage = styled.img`
  border-radius: 8px;
  width: 40px;
  height: 40px;
  object-fit: cover;
`

/**
 *
 */
export const settings = {
  category: BLOCK_CATEGORIES.ESSENTIALS,
  type: 'IMAGE_LINK',
  databaseType: 'StartPageBlockImageLink',
  icon: {
    Component: CustomImageIcon,
    color: '#2c4bff',
  },
}

export const addButtonSettings = {
  label: 'Image Link',
  description: 'Links for everything',
}

export const editButtonSettings = {
  label: 'Image',
  contentField: 'caption',
}

/**
 *
 */
function CustomImageIcon({ block }: { block?: ImageLinkBlock }) {
  const { media } = block || {}
  const { url, altText } = media || {}

  const resizeUrl = url ? getResizeURL({ baseUrl: url, height: 40 }) : null

  return block && resizeUrl ? (
    <StyledImage src={resizeUrl} alt={altText} />
  ) : (
    <ImageIcon />
  )
}

/**
 *
 */
export function BlockForm({ block }: { block: ImageLinkBlock }) {
  const [imageBlock, setImageBlock] = useState(() => block)
  const [hasError, setHasError] = useState(false)

  const [state, setState] = useState<any>(() => ({
    caption: imageBlock?.caption || '',
    url: imageBlock?.url || '',
    altText: imageBlock?.media?.altText || '',
  }))

  const { saveBlock, response, changes, setChanges } = useSaveBlock({
    block: imageBlock,
  })

  const { imageUrl, setSelectedFile, imageUploading, errorUploading } =
    useImageUpload(imageBlock?.media?.url || '')

  useEffect(() => {
    hasError && setHasError(false)

    const hasBlockedLinks = catchBlockedLinks(state.url)
    hasBlockedLinks && setHasError(true)

    if (!hasBlockedLinks) {
      setChanges({
        caption: state.caption,
        url: state.url,
        media: {
          url: imageUrl,
          altText: state.altText,
        },
      })
    } else {
      setChanges(null)
    }
  }, [state, imageUrl])

  const saveOnBlur = () => {
    if (changes) {
      saveBlock()
    }
  }

  useEffect(() => {
    if (response && !imageBlock?.id) {
      setImageBlock(response.addBlock.block)
    }
  }, [response])

  useEffect(() => {
    if (changes?.media?.url == '') {
      return
    }
    if (changes?.media?.url !== imageBlock?.media?.url) {
      saveBlock()
    }
  }, [changes])

  return (
    <>
      <MediaWrapper>
        <UploadMedia
          smallPreview={block?.media?.url || ''}
          setSelectedFile={setSelectedFile}
          loading={imageUploading}
          errorUploading={errorUploading}
        />
        <Tip>Use GIFs to make your page pop ✨</Tip>
      </MediaWrapper>
      <FieldSet>
        <TextAreaWithLabel
          label="Image caption"
          id="textarea-image-caption"
          textAreaValue={state.caption}
          setTextAreaValue={(value) => setState({ ...state, caption: value })}
          saveOnBlur={saveOnBlur}
        />
        <InputWithLabel
          label="Link"
          inputValue={state.url}
          setInputValue={(value) => setState({ ...state, url: value })}
          openInNewTab
          saveOnBlur={saveOnBlur}
          hasError={hasError}
          errorMsg="This content goes against our Terms of Use. Please review the terms before adding your content."
        />
        <InputWithLabel
          label="Alt Text"
          inputValue={state.altText}
          setInputValue={(value) => setState({ ...state, altText: value })}
          saveOnBlur={saveOnBlur}
        />
      </FieldSet>
    </>
  )
}
