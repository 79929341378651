import React from 'react'
import Text from '@bufferapp/ui/Text'
import { Logo } from '@buffer-mono/sp-display'
import { BASE_URL } from '~/shared/constants'
import * as Icons from '@bufferapp/ui/Icon'
import {
  Wrapper,
  DomainURL,
  Status,
  Avatar,
  Name,
  Label,
  StatusText,
} from './styles'

/**
 *
 */
function PageOption({
  domain,
  logoUrl,
  logoAlt,
  title,
  isPublished,
  onClick,
  hasSetDomain,
}: {
  domain: string | null
  logoUrl: string
  logoAlt?: string
  title?: string
  isPublished?: boolean
  onClick?: () => void
  hasSetDomain: boolean
}) {
  const StatusIcon = isPublished ? Icons.Checkmark : Icons.Cross
  return (
    <Wrapper onClick={onClick}>
      <Avatar>
        <Logo url={logoUrl} alt={logoAlt} width={42} height={42} />
        <Status isPublished={isPublished}>
          <StatusIcon />
        </Status>
      </Avatar>
      <Label>
        {title && <Text type="label">{title}</Text>}
        <Name>
          {!isPublished && (
            <StatusText hasSetDomain={hasSetDomain}>Not Published</StatusText>
          )}
          {!!domain && hasSetDomain && (
            <DomainURL isPublished={isPublished}>
              {domain}
              {BASE_URL}
            </DomainURL>
          )}
        </Name>
      </Label>
    </Wrapper>
  )
}

export default PageOption
