import React from 'react'
import Button from '@bufferapp/ui/Button'
import { Checkmark, Add } from '@bufferapp/ui/Icon'

import Dropdown from '~/shared/components/Dropdown'
import {
  useStartPageContext,
  START_PAGES_LIMIT,
} from '~/modules/pages/state/context'
import type { StartPageData, HeaderBlock } from '@buffer-mono/sp-display'
import { useNavigate } from 'react-router-dom'
import PageOption from './PageOption'

import {
  Title,
  OptionsWrapper,
  OptionWrapper,
  CTAWrapper,
  WarningText,
  IconWrapper,
} from './styles'

/**
 *
 */
type StartPageInfo = {
  id: string
  domain: string | null
  logoUrl: string
  logoAlt: string
  title?: string
  isPublished?: boolean
  hasSetDomain: boolean
}

/**
 *
 */
function Selector(): JSX.Element | null {
  const [selected, setSelected] = React.useState<StartPageInfo>()

  const navigate = useNavigate()

  const { hasDomain, startPagesData, startPageData, loadStartPage } =
    useStartPageContext()

  React.useEffect(() => {
    if (!hasDomain || !startPagesData || selected) return
    onSelect(startPagesData[0])
  }, [hasDomain, startPagesData, selected])

  React.useEffect(() => {
    if (!startPageData) return
    onSelect(startPageData)
  }, [startPageData])

  const onAddNew = React.useCallback(() => {
    navigate('/onboarding')
  }, [navigate])

  const onSelect = (startPageData: StartPageData): void => {
    const startPageInfo = getStartPageInfo(startPageData)
    if (!startPageInfo) return
    setSelected(startPageInfo)
  }

  const navigateToStartPage = (startPageId: string): void => {
    if (!startPageId) return

    loadStartPage(startPageId)
    navigate(`/${startPageId}`)
  }

  const belowLimit = startPagesData && startPagesData.length < START_PAGES_LIMIT
  const canCreateStartPages = belowLimit

  const showDisplayButton = canCreateStartPages

  return selected ? (
    <Dropdown>
      <Dropdown.Button>
        <PageOption
          domain={selected.domain}
          logoUrl={selected.logoUrl}
          logoAlt={selected.logoAlt}
          title={selected.title}
          isPublished={selected.isPublished}
          hasSetDomain={selected.hasSetDomain}
        />
      </Dropdown.Button>
      <Dropdown.Content>
        <Title id="dropdown-label" type="p">
          My Start Pages
        </Title>
        <OptionsWrapper>
          <Dropdown.Options aria-labelledby="dropdown-label">
            {startPagesData?.map((startPage, index) => {
              const startPageInfo = getStartPageInfo(startPage)
              if (!startPageInfo) return null

              const {
                id,
                domain,
                logoUrl,
                logoAlt,
                title,
                isPublished,
                hasSetDomain,
              } = startPageInfo
              const isSelected = id === selected.id

              return (
                <OptionWrapper selected={isSelected} key={index}>
                  <PageOption
                    domain={domain}
                    logoUrl={logoUrl}
                    logoAlt={logoAlt}
                    title={title}
                    isPublished={isPublished}
                    onClick={(): void => {
                      onSelect(startPage)
                      navigateToStartPage(id)
                    }}
                    hasSetDomain={hasSetDomain}
                  />

                  {isSelected && (
                    <IconWrapper>
                      <Checkmark title="Selected" />
                    </IconWrapper>
                  )}
                </OptionWrapper>
              )
            })}
          </Dropdown.Options>
        </OptionsWrapper>
        <CTAWrapper withBorder={!showDisplayButton}>
          {showDisplayButton ? (
            <Button
              id="create-new-start-page"
              type="secondary"
              icon={<Add />}
              onClick={onAddNew}
              label="Create new Start Page"
              fullWidth
            />
          ) : (
            <WarningText>
              You reached the limit of {START_PAGES_LIMIT} Start Pages allowed
              per organization.
            </WarningText>
          )}
        </CTAWrapper>
      </Dropdown.Content>
    </Dropdown>
  ) : null
}

/**
 *
 */
export function getStartPageInfo(
  startPageData: StartPageData,
): StartPageInfo | null {
  const { id, domain, blocks, title, isPublished, organizationId } =
    startPageData

  const headerBlock = blocks?.find(
    (el) => el.__typename === 'StartPageBlockHeader',
  ) as HeaderBlock

  const logoMedia = headerBlock?.logoMedia

  const result =
    id && logoMedia
      ? {
          id,
          domain: domain || null,
          title,
          isPublished,
          logoUrl: logoMedia.url,
          logoAlt: logoMedia.altText,
          hasSetDomain: !!domain && domain !== organizationId,
        }
      : null

  return result
}

export default Selector
