import styled from 'styled-components'
import { fontFamily, fontWeight, lineHeight } from '@bufferapp/ui/style/fonts'
import { gray } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    padding: 16px;
  }
`

export const FakePrimaryButton = styled.a`
  background: #2c4bff;
  border-radius: 4px;
  width: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: 0px;
  text-align: left;

  :visited {
    color: white;
    text-decoration: none;
  }
`

export const Bottom = styled.div`
  display: flex;
  padding: 16px;
  background: #f5f5f5;
  width: 100%;
  justify-content: space-between;
`

export const TransparentButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    fill: #636363;
  }
`

export const LabelWrapper = styled.div`
  display: flex;

  svg {
    margin-left: 4px;
    color: ${gray};
    cursor: pointer;
  }
`
